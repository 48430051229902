<template>
    <div class="license">
        <div
            class="add-license"
            v-bind:class="{ hidden: !globalState.isAdmin }"
        >
            <div class="info upload">
                <div class="name kds-subtitle2">License Upload</div>
                <div class="description">
                    <input type="file" v-on:change="onFileChange" />
                </div>
            </div>

        <p class="kds-m-2">
            {{ form.content }}
        </p>
            <div class="submit">
                <kds-button @click="submit" :disabled="!selectedFile"
                    >Upload License</kds-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import licenseHandler from '@/lib/licenseHandler';

export default {
    data() {
        return {
            globalState: store.state,
            selectedFile: '',
            form: {
                node_id: null,
                content: null,
                valid_date: null
            }
        };
    },
    name: 'LscCreateLicense',
    props: {
        nodeId: String
    },
    created: function () {},
    methods: {
        onFileChange(e) {
            console.log(this.nodeId);
            this.selectedFile = e.target.files[0].name;
            var fr = new FileReader();
            fr.onload = () => {
                this.form.content = fr.result;
                this.form.node_id = this.nodeId;

                // get valid date
                try {
                    // decide which version of the license is being uploaded
                    if (fr.result.includes('lic.validDate=')) {
                        // legacy license
                        this.form.valid_date = new Date(
                            fr.result.split('lic.validDate=')[1].split('T')[0]
                        );
                    } else if (fr.result.includes('license.validDate=')) {
                        // current version of license
                        this.form.valid_date = new Date(
                            fr.result.split('license.validDate=')[1].split('T')[0]
                        );
                    } else {
                        console.warn('No valid license!');
                        this.form.valid_date = null;
                    }
                } catch (e) {
                    console.warn(e);
                    this.form.valid_date = null;
                }

                console.log(fr.result);
            };

            fr.readAsText(e.target.files[0]);
        },
        submit() {
            licenseHandler
                .createLicense(this.nodeId, this.form)
                .then((res) => {
                    console.log(res.data);
                    this.emitter.emit('lsc:event:newLicenseAdded');
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'name'
        'description';

    float: left;
    font-size: 0.8rem;
    font-size: 0.8rem;
    text-align: left;
    width: 30%;
    margin: 0.5rem;
}

.info.upload {
    width: 100%;
}

.name {
    grid-area: name;
    font-weight: bold;
}

.description {
    grid-area: description;
    font-size: 0.8rem;
}

.progress-bar-cont {
    width: 100%;
    float: left;
}

.progress-bar {
    display: block;
    float: left;
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 0.25rem;
}
</style>
