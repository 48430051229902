<template>
    <div class="active-node-view">
        <div class="node-detail-header">
            <span class="kds-h1">{{ currentNode.name }}</span>
        </div>

        <LscNoContent v-bind:dataArray="activeNode !== null ? [1] : []">
            <template v-slot:header-all>No Node Choosen</template>
            <template v-slot:content-admin>Add new Enterprise or </template>
            <template v-slot:content-all
                >Choose node from left navigation to display some
                content</template
            >
        </LscNoContent>

        <kds-tab
            v-bind:class="{ hidden: activeNode === null }"
            @click="activeTabChanged()"
            ref="kdsTabComponent"
        >
            <kds-tab-title
                slot="title"
                class="node-nav-tab"
                v-bind:hidden="!(globalState.isAdmin || (newsList && newsList.length > 0))"
                >News</kds-tab-title
            >
            <kds-tab-panel
                slot="panel"
            >
                <div class="news-list">
                    <draggable
                        v-model="newsList"
                        v-bind="dragOptions"
                        group="news"
                        @start="drag = true"
                        @end="drag = false"
                        item-key="id"
                        :disabled="
                            !globalState.isAdmin || globalState.dragDisabled
                        "
                        @change="newsRearranged"
                    >
                        <template #item="{ element }">
                            <div>
                                <LscNews v-bind:newsInfo="element" />
                            </div>
                        </template>
                    </draggable>

                    <LscNoContent v-bind:dataArray="newsList">
                        <template v-slot:header-all>No News</template>
                        <template v-slot:content-admin
                            >Create the first news on this Node</template
                        >
                        <template v-slot:content-user
                            >No News exist on this Node</template
                        >
                    </LscNoContent>
                    <LscLoadingSpinner
                        v-if="loadingSpinner.news"
                        class="kds-m-5"
                    />

                    <LscPopup
                        v-bind:popupOpen="popups.addNewsOpen"
                        v-bind:on-close="
                            () => {
                                popups.addNewsOpen = !popups.addNewsOpen;
                            }
                        "
                    >
                        <template v-slot:header>Add News</template>
                        <template v-slot:content>
                            <LscNewsAddNews
                                v-bind:on-close="
                                    () => {
                                        popups.addNewsOpen =
                                            !popups.addNewsOpen;
                                    }
                                "
                                v-bind:nodeId="activeNode"
                            />
                        </template>
                    </LscPopup>
                    <div class="centered">
                        <kds-button
                            color="tertiary"
                            @click="
                                () => {
                                    popups.addNewsOpen = !popups.addNewsOpen;
                                }
                            "
                            v-bind:class="{ hidden: !globalState.isAdmin }"
                            class="kds-m-2 add-document"
                            >Add News</kds-button
                        >
                    </div>
                </div>
            </kds-tab-panel>
            <kds-tab-title
                slot="title"
                class="node-nav-tab"
                v-bind:hidden="!(globalState.isAdmin || (builds && builds.length > 0))"
                active
                >Builds</kds-tab-title
            >
            <kds-tab-panel
                slot="panel"
            >
                <div class="builds">
                    <span class="available-products">
                        Displayed Products:

                        <kds-tag-actions
                            v-bind:product-name="product.product_name"
                            v-for="product in availableProducts"
                            :key="product.product_name"
                            class="kds-m-1"
                            color="informative"
                            height="s"
                            v-bind:selected="product.is_visible"
                            @click="toggleProduct"
                            clickable
                        >
                            {{ product.product_name }}
                        </kds-tag-actions>
                    </span>

                    <draggable
                        v-model="builds"
                        v-bind="dragOptions"
                        group="build"
                        @start="drag = true"
                        @end="drag = false"
                        item-key="id"
                        :disabled="
                            !globalState.isAdmin || globalState.dragDisabled
                        "
                        @change="buildsRearranged"
                    >
                        <template #item="{ element }">
                            <div>
                                <LscBuild v-bind:buildInfo="element" />
                            </div>
                        </template>
                    </draggable>

                    <LscNoContent v-bind:dataArray="builds">
                        <template v-slot:header-all>No Builds</template>

                        <template v-slot:content-admin
                            >Create the first build on this Node</template
                        >
                        <template v-slot:content-user
                            >No Build exist on this Node</template
                        >
                    </LscNoContent>
                    <LscLoadingSpinner
                        v-if="loadingSpinner.build"
                        class="kds-m-5"
                    />
                    <div class="centered">
                        <kds-button
                            color="tertiary"
                            @click="
                                () => {
                                    popups.addBuildOpen = !popups.addBuildOpen;
                                }
                            "
                            v-bind:class="{ hidden: !globalState.isAdmin }"
                            class="kds-m-2 add-document"
                            >Add Build</kds-button
                        >
                    </div>
                </div>
            </kds-tab-panel>
            <kds-tab-title
                slot="title"
                class="node-nav-tab"
                v-bind:hidden="!(globalState.isAdmin || (licenses && licenses.length > 0))"
                >Licenses</kds-tab-title
            >
            <kds-tab-panel
                slot="panel"
            >
                <div class="licenses">
                    <draggable
                        v-model="licenses"
                        v-bind="dragOptions"
                        group="licenses"
                        @start="drag = true"
                        @end="drag = false"
                        item-key="id"
                        :disabled="
                            !globalState.isAdmin || globalState.dragDisabled
                        "
                        @change="licensesRearranged"
                    >
                        <template #item="{ element }">
                            <div>
                                <LscLicense v-bind:licenseInfo="element" />
                            </div>
                        </template>
                    </draggable>

                    <LscNoContent v-bind:dataArray="licenses">
                        <template v-slot:header-all>No License</template>

                        <template v-slot:content-admin
                            >Create the first license on this Node</template
                        >
                        <template v-slot:content-user
                            >No License exist on this Node</template
                        >
                    </LscNoContent>
                    <LscLoadingSpinner
                        v-if="loadingSpinner.licenses"
                        class="kds-m-5"
                    />
                    <div class="centered">
                        <kds-button
                            color="tertiary"
                            @click="
                                () => {
                                    popups.addLicenseOpen =
                                        !popups.addLicenseOpen;
                                }
                            "
                            v-bind:class="{ hidden: !globalState.isAdmin }"
                            class="kds-m-2 add-document"
                            >Add License</kds-button
                        >
                    </div>
                    <LscPopup
                        v-bind:popupOpen="popups.addLicenseOpen"
                        v-bind:on-close="
                            () => {
                                popups.addLicenseOpen = !popups.addLicenseOpen;
                            }
                        "
                    >
                        <template v-slot:header>Add License</template>
                        <template v-slot:content>
                            <LscCreateLicense v-bind:nodeId="activeNode" />
                        </template>
                    </LscPopup>
                </div>
            </kds-tab-panel>
        </kds-tab>

        <LscPopup
            v-bind:popupOpen="popups.addBuildOpen"
            v-bind:on-close="
                () => {
                    popups.addBuildOpen = !popups.addBuildOpen;
                }
            "
        >
            <template v-slot:header>Add Build</template>
            <template v-slot:content>
                <LscCreateBuild v-bind:parentNode="activeNode" />
            </template>
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import nodeHandler from '@/lib/nodeHandler';
import newsHandler from '@/lib/newsHandler';
import buildHandler from '@/lib/buildHandler';
import licenseHandler from '@/lib/licenseHandler';
import LscNewsAddNews from '@/components/LscNews/LscNewsAddNews.vue';
import LscNews from '@/components/LscNews/LscNews.vue';
import LscBuild from '@/components/LscBuild/LscBuild.vue';
import LscCreateBuild from '@/components/LscBuild/LscCreateBuild.vue';
import LscLicense from '@/components/LscLicense/LscLicense.vue';
import LscCreateLicense from '@/components/LscLicense/LscCreateLicense.vue';
import LscPopup from '@/components/LscStructure/LscPopup.vue';
import LscNoContent from '@/components/LscStructure/LscNoContent.vue';
import LscLoadingSpinner from '@/components/LscStructure/LscLoadingSpinner.vue';
import draggable from 'vuedraggable';

export default {
    data() {
        return {
            builds: null,
            newsList: null,
            products: [],
            activeNode: null,
            licenses: [],
            currentNode: {
                id: null,
                name: null,
                type: null
            },
            globalState: store.state,
            activeProduct: 'Lion',
            popups: {
                addLicenseOpen: false,
                addBuildOpen: false,
                addNewsOpen: false
            },
            loadingSpinner: {
                news: false,
                build: false,
                licenses: false
            },
            availableProducts: []
        };
    },
    name: 'ActiveNodeView',
    components: {
        LscNewsAddNews,
        LscNews,
        LscBuild,
        LscCreateBuild,
        LscLicense,
        LscCreateLicense,
        LscPopup,
        LscNoContent,
        LscLoadingSpinner,
        draggable
    },
    props: {},
    mounted: function () {
        this.emitter.on('lsc:event:chooseNode', (activeNode) => {
            if (this.activeNode !== activeNode) {
                this.activeNode = activeNode;
                this.getCurrentNode();
            }
        });
        this.emitter.on('lsc:event:newBuildAdded', () => {
            this.popups.addBuildOpen = false;
            this.loadAvailableProducts();
        });
        this.emitter.on('lsc:event:newLicenseAdded', () => {
            this.popups.addLicenseOpen = false;
            this.loadLicenses();
        });
        this.emitter.on('lsc:event:newNewsAdded', () => {
            this.loadNews();
        });
    },
    methods: {
        updateCurrentTabBasedOnRounter() {
            let tabName = this.$route.params.tabName;
            if (tabName !== "") {
                let tabTitles =
                    this.$refs.kdsTabComponent.querySelectorAll(
                        'kds-tab-title.node-nav-tab'
                    );
                if (tabTitles && tabTitles.length > 0) {
                    let targetIndex = 0;
                    for (let index = 0; index < tabTitles.length; index++) {
                        const tab = tabTitles[index];
                        if (tab.textContent === tabName) {
                            targetIndex = index + 1;
                        }
                    }
                    this.$refs.kdsTabComponent.setAttribute("current", targetIndex);
                }
            }
        },
        activeTabChanged() {
            let tabComponent = this.$refs.kdsTabComponent;
            let tabTitles =
                    this.$refs.kdsTabComponent.querySelectorAll(
                        'kds-tab-title.node-nav-tab'
                    );

            let activeTabName = tabTitles[parseInt(tabComponent.getAttribute('current'))-1].textContent;
            // ROUTER
            this.$router.replace({ params: { tabName: activeTabName } });
        },
        loadNews() {
            this.loadingSpinner.news = true;
            newsHandler.getNewsByNode(this.activeNode).then((b) => {
                this.newsList = b.data;
                this.loadingSpinner.news = false;
            });
        },
        toggleProduct(e) {
            // console.log(e);

            let productName = e.srcElement.getAttribute('product-name');
            let productIsVisible = e.srcElement.selected;

            let _availableProducts = [];

            let ctProductVisible = 0;

            this.availableProducts.map((p) => {
                if (p.product_name === productName) {
                    p.is_visible = productIsVisible;
                }

                if (p.is_visible) {
                    ctProductVisible = ctProductVisible + 1;
                }

                _availableProducts.push(p);
            });

            // display a product if nothing is selected
            if (ctProductVisible === 0) {
                _availableProducts[0].is_visible = true;
            }

            this.availableProducts = _availableProducts;

            this.loadBuilds();
        },
        loadBuilds() {
            this.loadingSpinner.build = true;

            let requestedProducts = [];
            this.availableProducts.map((p) => {
                if (p.is_visible) {
                    requestedProducts.push(p);
                }
            });

            if (this.activeNode !== null) {
                buildHandler
                    .getSpecificBuildsForNode(
                        this.activeNode,
                        requestedProducts
                    )
                    .then((b) => {
                        this.builds = b.data;
                        // console.log('load-builds-ended');
                        this.loadingSpinner.build = false;
                    });
            } else {
                console.log("No Node Choosen - build can't be loaded");
                console.log('load-builds-cancelled');
                this.loadingSpinner.build = false;
            }
        },
        loadAvailableProducts() {
            if (this.activeNode !== null) {
                buildHandler
                    .getProductNames(this.activeNode)
                    .then((productNames) => {
                        this.availableProducts = [];

                        productNames.data.map((p) => {
                            p.is_visible = true;
                            this.availableProducts.push(p);
                        });

                        this.loadBuilds();
                    });
            } else {
                console.log("No Node Choosen - products can't be loaded");
                console.log('load-products-cancelled');
                this.loadingSpinner.build = false;
            }
        },
        loadProducts() {
            // TODO use output - at this time it is not used
            buildHandler.getProductNames(this.activeNode).then((b) => {
                this.products = b.data;
            });
        },
        loadLicenses() {
            this.loadingSpinner.licenses = true;
            licenseHandler.getLicensesForNode(this.activeNode).then((l) => {
                this.licenses = l.data;
                this.loadingSpinner.licenses = false;
            });
        },
        getCurrentNode() {
            if (this.activeNode !== null) {
                nodeHandler.getSpecific(this.activeNode).then((node) => {
                    this.currentNode = node.data;
                    // TODO optimize
                    this.loadNews();
                    this.loadAvailableProducts();
                    this.loadProducts();
                    this.loadLicenses();
                });
            } else {
                console.log('No Node Selected');
            }
        },
        newsRearranged() {
            newsHandler
                .rearrangeNewsByNode(this.newsList.map((_news)=>{return {id: _news.id}}), this.activeNode)
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
        },
        buildsRearranged() {
            buildHandler
                .rearrangeBuildsByNode(this.builds.map((_build)=>{return {id: _build.id}}), this.activeNode)
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
        },
        licensesRearranged() {
            licenseHandler
                .rearrangeLicenses(this.licenses.map((_lic)=>{return {id: _lic.id}}))
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    updated: function () {
        this.updateCurrentTabBasedOnRounter();
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* DRAG STYLES */

.available-products {
    display: block;
    padding: 0.8rem 1rem 0.5rem 1rem;
}

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

.active-node-view {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'node-detail-header'
        'node-content';
    height: 100vh;
}

.node-detail-header {
    grid-area: node-detail-header;
    padding: 1rem;
}

kds-tab {
    grid-area: node-content;
}

.licenses,
.builds,
.news-list {
    height: 87vh;
    overflow-y: scroll;
}
</style>