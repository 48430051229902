<template>
    <div class="org-node">
        <div
            class="tree-item"
            v-bind:class="{ active: isActive }"
            v-on:click="setActive()"
        >
            <div class="tree-item-spacer">
                <div class="spacer" :class="`sp-${spacer}`"></div>
            </div>
            <div class="tree-item-toggle">
                <button class="toggle-button">
                    <span class="material-icons-outlined md-18">
                        {{
                            nodes && nodes.length > 0
                                ? childsVisible
                                    ? 'arrow_drop_down'
                                    : 'arrow_right'
                                : ''
                        }}
                    </span>
                </button>
            </div>
            <div class="tree-item-type-icon">
                <span class="material-icons-outlined md-18">
                    {{
                        type === 'ENTERPRISE'
                            ? 'public'
                            : type === 'SITE'
                            ? 'business'
                            : 'precision_manufacturing'
                    }}
                </span>
            </div>
            <div class="tree-item-name">
                <p class="kds-caption">{{ name }}</p>
            </div>
            <div
                class="tree-item-options"
                v-bind:class="{ hidden: !globalState.isAdmin }"
            >
                <button v-on:click="openMoreOptions()">
                    <span class="material-icons-outlined md-18">
                        more_horiz
                    </span>
                </button>
            </div>
        </div>

        <div class="popup-menue" v-bind:class="{ active: popupMenueVisible }">
            <ul>
                <li
                    v-on:click="
                        () => {
                            popups.editNode = !popups.editNode;
                            loadLinkedRolls();
                        }
                    "
                >
                    Edit
                </li>
                <li
                    v-on:click="
                        () => {
                            popups.createChild = !popups.createChild;
                        }
                    "
                >
                    Create Child
                </li>
                <li v-on:click="remove()">Delete</li>
            </ul>
        </div>

        <!-- here come the child items-->
        <draggable
            v-model="nodes"
            v-bind="dragOptions"
            :group="nodeId"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
            :disabled="!globalState.isAdmin"
            @change="nodesRearranged"
        >
            <template #item="{ element }">
                <div class="children" v-bind:class="{ hidden: !childsVisible }">
                    <LscOrgNode
                        v-bind:nodeId="element.id"
                        v-bind:name="element.name"
                        v-bind:type="element.type"
                        v-bind:spacer="spacer + 1"
                        v-bind:parent-perform-load="performLoad"
                        v-bind:parent-open-node="openNode"
                        v-bind:isInitialActive="isInitialActive"
                    />
                </div>
            </template>
        </draggable>

        <!-- here are popups-->
        <LscPopup
            v-bind:popupOpen="popups.createChild"
            v-bind:on-close="
                () => {
                    popups.createChild = !popups.createChild;
                }
            "
        >
            <template v-slot:header>Create Child Org Node</template>
            <template v-slot:content>
                <kds-input
                    class="kds-m-2"
                    label="Child Node Name"
                    name="name"
                    :value="form.name"
                    @input="form.name = $event.target.value"
                ></kds-input>
            </template>
            <template v-slot:footer>
                <kds-button @click="submitAddChild" class="kds-m-1"
                    >Add</kds-button
                >
            </template>
        </LscPopup>

        <LscPopup
            v-bind:popupOpen="popups.editNode"
            v-bind:on-close="
                () => {
                    popups.editNode = !popups.editNode;
                }
            "
        >
            <template v-slot:header>Edit Org Node</template>
            <template v-slot:content>
                <div class="linked-rolls" v-if="type === 'ENTERPRISE'">
                    <span v-for="group in linkedRolls" :key="group">
                        <kds-tag-actions
                            v-bind:roll-name="group.roll_name"
                            class="kds-m-1"
                            height="m"
                            @close="chipRollClose"
                            icon-name="groups"
                            removable
                        >
                            {{ group.roll_name }}
                        </kds-tag-actions>
                    </span>
                </div>
                
                <kds-dropdown
                 v-if="
                        type === 'ENTERPRISE' &&
                        linkedRolls &&
                        linkedRolls.length > 0
                    "
                    class="kds-m-2"
                    label="Roll Name"
                    name="roll_name"
                    :value="[form.roll_name]"
                    @input="form.roll_name = $event.target.value[0]"
                    :options="availableRolls"
                ></kds-dropdown>

                <p class="kds-caption"  v-if="
                        type === 'ENTERPRISE' &&
                        linkedRolls &&
                        linkedRolls.length > 0
                    ">
                    if the required roll is not visible - check if your user has
                    the role and try to log out and log in again!
                </p>

                <kds-button
                    v-if="type === 'ENTERPRISE'"
                    @click="addLinkedRolls"
                    class="kds-m-1"
                    >Add Roll</kds-button
                >

                <kds-input
                    class="kds-m-2"
                    label="Current Node Name"
                    name="name"
                    :value="nodeForm.name"
                    @input="nodeForm.name = $event.target.value"
                ></kds-input>
            </template>
            <template v-slot:footer>
                <kds-button @click="editCurrentNode" class="kds-m-1"
                    >Edit</kds-button
                >
            </template>
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import nodeHandler from '@/lib/nodeHandler';
import rollHandler from '@/lib/rollHandler';
import LscPopup from '@/components/LscStructure/LscPopup';

import draggable from 'vuedraggable';

export default {
    data() {
        return {
            isActive: false,
            nodes: null,
            popupMenueVisible: false,
            childsVisible: false,
            globalState: store.state,
            linkedRolls: [],
            popups: {
                createChild: false,
                editNode: false
            },
            form: {
                name: '',
                type: '',
                parent_id: null,
                node_id: null,
                roll_name: 'lsc-user',
                is_admin: false
            },
            nodeForm: {
                name: ''
            },
            availableRolls: [
                { value: 'lsc-user', label: 'lsc-user' },
                { value: 'lsc-admin', label: 'lsc-admin' }
            ]
        };
    },
    name: 'LscOrgNode',
    props: {
        msg: String,
        nodeId: String,
        name: String,
        isInitialActive: String,
        type: String,
        spacer: Number,
        parentPerformLoad: Function,
        parentOpenNode: Function
    },
    components: {
        LscPopup,
        draggable
    },
    created: function () {
        this.performLoad();
        this.nodeForm.name = this.name;
        this.form.node_id = this.nodeId;

        let tmpDropdownRoles = [];
        try {
            const kcRoles =
                this.keycloak.tokenParsed.resource_access[this.globalState.resourceClient].roles;

            kcRoles.map((role) => {
                tmpDropdownRoles.push({
                    value: role,
                    label: role
                });
            });
        } catch (e) {
            console.log(e);
        }

        if (tmpDropdownRoles !== []) {
            this.availableRolls = tmpDropdownRoles;
        }
    },
    mounted: function () {
        this.emitter.on('lsc:event:chooseNode', (activeNode) => {
            this.isActive = activeNode === this.nodeId;
        });

        if (this.isInitialActive === this.nodeId) {
            this.setActive();
            if (this.parentOpenNode) {
                this.parentOpenNode();
            }
        }

        const el = this.$el;

        el.clickOutsideEvent = (event) => {
            // here I check that click was outside the el and his children
            if (!(el == event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                this.closeMoreOptions();
            }
        };
        document.addEventListener('click', el.clickOutsideEvent);
    },
    unmounted: function () {
        const el = this.$el;
        document.removeEventListener('click', el.clickOutsideEvent);
    },
    methods: {
        chipRollClose(e) {
            rollHandler
                .deleteRoll(
                    this.form.node_id,
                    e.target.getAttribute('roll-name')
                )
                .then(() => {
                    this.performLoad();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        setActive() {
            this.emitter.emit('lsc:event:chooseNode', this.nodeId);
            this.toggleChildren();
            // ROUTER
            this.$router.replace({ params: { nodeId: this.nodeId } });
        },
        toggleChildren() {
            // toggles the child items
            this.childsVisible = !this.childsVisible;
        },
        openNode() {
            this.toggleChildren();
            if (this.parentOpenNode) {
                this.parentOpenNode();
            }
        },
        closeMoreOptions() {
            // closes the More option menue
            this.popupMenueVisible = false;
        },
        openMoreOptions() {
            // toggles the More option menue
            this.popupMenueVisible = !this.popupMenueVisible;
        },
        addLinkedRolls() {
            console.log('add linked group');
            rollHandler
                .createRoll(this.form)
                .then(() => {
                    this.performLoad();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        loadLinkedRolls() {
            if (this.globalState.isAdmin) {
                rollHandler
                    .getRollsByNode(this.nodeId)
                    .then((response) => {
                        this.linkedRolls = response.data;
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        performLoad() {
            // loads the specific node
            nodeHandler
                .getChildren(this.nodeId)
                .then((response) => {
                    this.nodes = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
            this.loadLinkedRolls();
        },
        editCurrentNode() {
            this.popupMenueVisible = false;
            this.popups.editNode = false;

            console.log(this.nodeForm);

            nodeHandler
                .editNode(this.nodeId, this.nodeForm)
                .then(() => {
                    this.parentPerformLoad();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        nodesRearranged() {
            nodeHandler
                .rearrangeNodes(this.nodes)
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
        },
        submitAddChild() {
            this.popupMenueVisible = false;
            this.popups.createChild = false;

            nodeHandler
                .createNode(this.nodeId, this.type, this.form)
                .then(() => {
                    this.performLoad();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        remove() {
            if (confirm('do you really want to delete this node')) {
                nodeHandler
                    .deleteNode(this.nodeId)
                    .then(() => {
                        this.parentPerformLoad();
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* DRAG STYLES */

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

.tree-item {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 3fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'tree-item-spacer tree-item-toggle tree-item-type-icon tree-item-name tree-item-options';
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 0;
    user-select: none;
}

.tree-item.active {
    background-color: var(--kds-color-sema7);
}

.tree-item.active .material-icons-outlined {
    color: var(--kds-color-acce2);
}

.tree-item-spacer {
    grid-area: tree-item-spacer;
}

.tree-item-toggle {
    grid-area: tree-item-toggle;
}

.tree-item-type-icon {
    grid-area: tree-item-type-icon;
}

.tree-item-name {
    grid-area: tree-item-name;
    text-align: left;
}

.tree-item-options {
    grid-area: tree-item-options;
}

.tree-item-options > button {
    display: none;
}

.tree-item.active .tree-item-options > button {
    display: block;
}

.tree-item.active .tree-item-options.hidden > button {
    display: none;
}

.spacer.sp-0 {
    width: 0rem;
}

.spacer.sp-1 {
    width: 1rem;
}

.spacer.sp-2 {
    width: 2rem;
}

.children {
    display: block;
}

.children.hidden {
    display: none;
}

.org-node button {
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
}

.popup-menue {
    display: none;

    background: var(--kds-color-atmo1);
    border: 2px solid var(--kds-color-acce2);

    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
        0 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    z-index: 100;
}

.popup-menue.active {
    display: block;
}

.popup-menue > ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    cursor: pointer;
    margin: 0.25rem 0;
}

.popup-menue > ul > li {
    padding: 0.5rem 1rem;
    width: 100%;
    display: inline-block;
}

.popup-menue > ul > li:hover {
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    width: 100%;
}
</style>
