<template>
    <div class="lsc-popup" v-bind:class="{ hidden: !popupOpen }">
        <div class="popup">
            <div class="header kds-h5">
                <slot name="header"></slot>
                <button v-on:click="close()">
                    <span class="material-icons-outlined"> close </span>
                </button>
            </div>
            <div class="content">
                <slot name="content"></slot>
            </div>
            <div class="footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';

export default {
    components: {},
    data() {
        return {
            isOpen: false,
            globalStateFunc: store
        };
    },
    name: 'LscPopup',
    props: {
        popupOpen: Boolean,
        onClose: Function
    },
    created: function () {},
    methods: {
        close() {
            this.onClose();
        }
    },
    watch: { 
        popupOpen: function(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.globalStateFunc.setDragDisabled(newVal, this.el);
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-popup {
    background-color: rgba(0, 0, 0, 0.75);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 1000;
}

.lsc-popup.hidden {
    display: none;
}

.lsc-popup > .popup {
    background-color: var(--kds-color-atmo1);
    display: block;
    min-width: 60%;
    min-height: 50%;
    max-width: 90%;
    max-height: 90%;
    overflow: scroll;
}

.lsc-popup > .popup > .header {
    margin: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header > button {
    background: none;
    border: none;
    cursor: pointer;
}

.header > button > span {
    color: var(--kds-color-acce1);
}

.lsc-popup > .popup > .content {
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-start;
}

.lsc-popup > .popup > .footer {
    margin: 1rem;
    display: flex;
    justify-content: flex-end;
}

@media only screen and (max-device-width: 600px) {
    .lsc-popup > .popup {
        background-color: var(--kds-color-atmo1);
        display: block;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
        overflow: scroll;
    }
}
</style>
