const { dfRq, fileUploadRequest } = require('./endpointConnector');


function getDocumentsForBuild(buildId) {
    return dfRq({
        method: "get",
        urlPath: `/documents/from/${buildId}`
    }, {}, {}, true);
}

function uploadDocument(file, progEv) {
    return fileUploadRequest(`/documents/upload`, file, progEv);
}

function createDocument(formData) {
    return dfRq({
        method: "put",
        urlPath: `/documents/`
    }, {}, formData, true);
}

function editDocument(formData) {
    return dfRq({
        method: "patch",
        urlPath: `/documents/`
    }, {}, formData, true);
}

function downloadDocument(documentId) {
    return dfRq({
        method: "get",
        urlPath: `/documents/${documentId}/download`,
        responseType: 'blob',
    }, {}, {}, true);
}

function deleteDocument(documentId) {
    return dfRq({
        method: "delete",
        urlPath: `/documents/${documentId}`,
    }, {}, {}, true);
}

function rearrangeDocuments(objectData) {

    const formData = {};
    formData.documentList = JSON.stringify(objectData);

    return dfRq({
        method: "post",
        urlPath: `/documents/rearrange`,
    }, {}, formData, true);
}

module.exports = {
    getDocumentsForBuild,
    downloadDocument,
    uploadDocument,
    createDocument,
    editDocument,
    deleteDocument,
    rearrangeDocuments
};