<template>
    <div class="add-build">
        <div class="info upload">
            <div class="name kds-subtitle2">Document Upload</div>
            <div class="description">
                <input ref="doc-input" type="file" v-on:change="onFileChange" />
            </div>
        </div>

        <kds-dropdown
            :value="[form.type]"
            @input="form.type = $event.target.value[0]"
            class="kds-mt-3"
            label="Document Type"
            placeholder="Make selection"
            name="product_name"
            :options="dropdownValues"
        ></kds-dropdown>

        <kds-input
            label="Document Name"
            name="name"
            :value="form.name"
            @input="form.name = $event.target.value"
        ></kds-input>

        <div class="submit">
            <kds-button
                class="kds-m-2"
                @click="submit"
                :disabled="!selectedFile"
                ><span class="material-icons-outlined md-18"> upload_file </span
                >Upload Document</kds-button
            >
        </div>

        <div class="progress-bar-cont">
            <div
                v-if="progress"
                class="progress-bar kds-overline"
                :style="{ width: progress }"
            >
                {{ progress }}
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import documentHandler from '@/lib/documentHandler';

export default {
    data() {
        return {
            globalState: store.state,
            selectedFile: '',
            progress: null,
            dropdownValues: [
                { value: 'DOCU', label: 'DOCU' },
                { value: 'ISSUE', label: 'ISSUE' },
                { value: 'RELEASE_NOTE', label: 'RELEASE_NOTE' },
                { value: 'ZIP', label: 'ZIP' }
            ],
            form: {
                name: null,
                type: 'DOCU',
                file_path: '',
                build_id: '',
                mimetype: ''
            },
            templateForm: {
                name: null,
                type: 'DOCU',
                file_path: '',
                build_id: '',
                mimetype: ''
            }
        };
    },
    name: 'LscCreateDocument',
    props: {
        buildId: String
    },
    created: function () {},
    methods: {
        resetInputs() {
            this.form = this.templateForm;
            this.progress = null;
            this.selectedFile = '';
            this.$refs['doc-input'].value = null;
        },
        onFileChange(e) {
            const selectedFile = e.target.files[0]; // accessing file
            this.selectedFile = selectedFile;
        },
        submit() {
            documentHandler
                .uploadDocument(this.selectedFile, this.onProgress)
                .then((res) => {
                    // file is uploaded
                    this.form.file_path = res.data.filename;
                    this.form.mimetype = res.data.mimetype;
                    this.form.build_id = this.buildId;

                    documentHandler
                        .createDocument(this.form)
                        .then(() => {
                            this.emitter.emit('lsc:event:newDocumentAdded');
                            this.resetInputs();
                        });
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onProgress(ProgressEvent) {
            let progress =
                Math.round((ProgressEvent.loaded / ProgressEvent.total) * 100) +
                '%';
            this.progress = progress;
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'name'
        'description';

    float: left;
    font-size: 0.8rem;
    font-size: 0.8rem;
    text-align: left;
    width: 30%;
    margin: 0.5rem;
}

.name {
    grid-area: name;
    font-weight: bold;
    padding: 0.5rem 0;
    text-align: center;
}

.description {
    grid-area: description;
    font-size: 0.8rem;
}

.info.upload {
    width: 100%;
}

.progress-bar-cont {
    width: 100%;
    float: left;
}

.progress-bar {
    display: block;
    float: left;
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 0.25rem;
}
</style>
