<template>
    <div class="folder-node">
        <div
            class="tree-item"
            v-bind:class="{ active: isActive }"
            v-on:click="setActive()"
        >
            <div class="tree-item-spacer">
                <div class="spacer" :class="`sp-${spacer}`"></div>
            </div>
            <div class="tree-item-toggle">
                <button class="toggle-button">
                    <span class="material-icons-outlined md-18">
                        {{
                            folderNode && !folderNode.leaf
                                ? childsVisible
                                    ? 'arrow_drop_down'
                                    : 'arrow_right'
                                : ''
                        }}
                    </span>
                </button>
            </div>
            <div class="tree-item-type-icon">
                <span class="material-icons-outlined md-18">
                    {{
                        folderNode && folderNode.type === 'asset'
                            ? 'insert_drive_file'
                            : 'folder'
                    }}
                </span>
            </div>
            <div class="tree-item-name">
                <p class="kds-caption">
                    {{
                        folderNode && folderNode.text
                            ? folderNode.text
                            : 'no text'
                    }}
                </p>
            </div>
            <div
                class="tree-item-options"
                v-bind:class="{ hidden: !globalState.isAdmin }"
            >
                <button class="choose-nexus-item" v-if="folderNode.leaf" v-on:click="chooseNexusItem()">
                    <span class="material-icons-outlined md-18"> check </span>
                </button>
            </div>
        </div>

        <!-- here come the child items-->
        <div v-for="node in nodes" :key="node.id">
            <div class="children" v-bind:class="{ hidden: !childsVisible }">
                <LscFolderNode
                    v-bind:folderNode="node"
                    v-bind:spacer="spacer + 1"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import nexusHandler from '@/lib/nexusHandler';
export default {
    data() {
        return {
            isActive: false,
            nodes: null,
            childsVisible: false,
            globalState: store.state
        };
    },
    name: 'LscFolderNode',
    props: {
        folderNode: Object,
        spacer: Number
    },
    components: {},
    created: function () {
        //this.loadFolderNodeChilds();
    },
    methods: {
        loadFolderNodeChilds() {
            if (this.folderNode && !this.folderNode.leaf) {
                nexusHandler
                    .getChildNodes(this.folderNode.id)
                    .then((res) => {
                        console.log(res);
                        console.log(res.data);
                        this.nodes = res.data;
                        console.log(this.nodes);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        toggleChildren() {
            // toggles the child items
            this.childsVisible = !this.childsVisible;
        },
        setActive() {
            console.log('setNodeActive');
            this.loadFolderNodeChilds();
            this.toggleChildren();
        },
        chooseNexusItem() {
            if (this.folderNode.leaf) {
                this.emitter.emit(
                    'lsc:event:nexusItemChoosen', 
                        this.folderNode
                    
                    
                );
            }
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* DRAG STYLES */

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

.tree-item {
    display: grid;
    grid-template-columns: min-content 1fr 1fr 3fr 1fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'tree-item-spacer tree-item-toggle tree-item-type-icon tree-item-name tree-item-options';
    width: 100%;
    cursor: pointer;
    padding: 0.5rem 0;
    user-select: none;
}

.tree-item:hover {
    background-color: var(--kds-color-sema7);
}

.tree-item.active {
    background-color: var(--kds-color-sema7);
}

.tree-item.active .material-icons-outlined {
    color: var(--kds-color-acce2);
}

.tree-item-spacer {
    grid-area: tree-item-spacer;
}

.tree-item-toggle {
    grid-area: tree-item-toggle;
}

.tree-item-type-icon {
    grid-area: tree-item-type-icon;
}

.tree-item-name {
    grid-area: tree-item-name;
    text-align: left;
}

.tree-item-options {
    grid-area: tree-item-options;
}

.tree-item-options > button {
    display: none;
}

.tree-item-options > button {
    display: block;
}

button.choose-nexus-item {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

button.choose-nexus-item:hover {
    color: white;
        background-color: var(--kds-color-acce2h);
}

.spacer.sp-0 {
    width: 0rem;
}

.spacer.sp-1 {
    width: 1rem;
}

.spacer.sp-2 {
    width: 2rem;
}
.spacer.sp-3 {
    width: 3rem;
}
.spacer.sp-4 {
    width: 4rem;
}
.spacer.sp-5 {
    width: 5rem;
}
.spacer.sp-6 {
    width: 6rem;
}

.children {
    display: block;
}

.children.hidden {
    display: none;
}

.folder-node button {
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
}

.popup-menue {
    display: none;

    background: var(--kds-color-atmo1);
    border: 2px solid var(--kds-color-acce2);

    max-width: 80%;
    overflow-y: auto;
    overflow-x: hidden;
    contain: content;
    will-change: transform;
    box-shadow: 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%),
        0 3px 14px 2px rgb(0 0 0 / 12%);
    border-radius: 4px;
    z-index: 100;
}

.popup-menue.active {
    display: block;
}

.popup-menue > ul {
    text-align: left;
    list-style-type: none;
    padding: 0;
    cursor: pointer;
    margin: 0.25rem 0;
}

.popup-menue > ul > li {
    padding: 0.5rem 1rem;
    width: 100%;
    display: inline-block;
}

.popup-menue > ul > li:hover {
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    width: 100%;
}
</style>
