const { dfRq, fileUploadRequest } = require('./endpointConnector');


function getSpecificBuildsForNode(nodeId, products) {

    const formData = {};
    formData.products = JSON.stringify(products);

    return dfRq({
        method: "post",
        urlPath: `/builds/from/${nodeId}`
    }, {}, formData, true);
}

function deleteBuild(buildId) {
    return dfRq({
        method: "delete",
        urlPath: `/builds/${buildId}`
    }, {}, {}, true);
}

function getProductNames(nodeId) {
    return dfRq({
        method: "get",
        urlPath: `/builds/products/${nodeId}`
    }, {}, {}, true);
}

function getDownloadHistoryForBuild(buildId) {
    return dfRq({
        method: "get",
        urlPath: `/builds/download-hist/${buildId}`
    }, {}, {}, true);
}
function getLinkedNodes(buildId) {
    return dfRq({
        method: "get",
        urlPath: `/builds/linked-nodes-by/${buildId}`
    }, {}, {}, true);
}

function createBuild(currentNode, formData) {
    formData.link_to = currentNode;

    return dfRq({
        method: "put",
        urlPath: `/builds/`
    }, {}, formData, true);
}

function editBuild(buildId, formData) {
    return dfRq({
        method: "patch",
        urlPath: `/builds/${buildId}`
    }, {}, formData, true);
}


function linkBuildAndNode(buildId, nodeId) {
    return dfRq({
        method: "put",
        urlPath: `/builds/nodeconnection/${buildId}/${nodeId}`
    }, {}, {}, true);
}

function unlinkBuildAndNode(buildId, nodeId) {
    return dfRq({
        method: "delete",
        urlPath: `/builds/nodeconnection/${buildId}/${nodeId}`
    }, {}, {}, true);
}

function uploadBuild(file, progEv) {
    return fileUploadRequest(`/builds/upload`, file, progEv);
}

function downloadBuild(currentNode, progresssId) {
    return dfRq({
        method: "get",
        urlPath: `/builds/${currentNode}/download`,
        responseType: 'blob',
    }, {}, {}, true, progresssId)
}

function changeState(build, node, newState) {
    return dfRq({
        method: "patch",
        urlPath: `/builds/${build}/${newState}/${node}`,
    }, {}, {}, true);
}

function rearrangeBuildsByNode(objectData, activeNode) {

    console.log(objectData);

    const formData = {};
    formData.nodeBuildList = JSON.stringify(objectData);

    return dfRq({
        method: "post",
        urlPath: `/builds/rearrange/${activeNode}`,
    }, {}, formData, true);
}

module.exports = {
    getSpecificBuildsForNode,
    deleteBuild,
    getProductNames,
    getDownloadHistoryForBuild,
    getLinkedNodes,
    createBuild,
    editBuild,
    linkBuildAndNode,
    unlinkBuildAndNode,
    uploadBuild,
    downloadBuild,
    changeState,
    rearrangeBuildsByNode
};