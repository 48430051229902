<template>
    <div class="lsc-license">
        <div class="option-bar">
            <kds-button
                color="tertiary"
                @click="deleteLicense()"
                class="kds-m-1"
                v-bind:class="{
                    hidden: !globalState.isAdmin
                }"
                >Delete License</kds-button
            >

            <kds-button
                color="tertiary"
                @click="downloadLicense()"
                class="kds-m-1"
                v-bind:class="{
                    hidden: (licenseDownloadError || !(globalState.isAdmin || licenseInfo.userIsOwner))
                }"
                >Download License</kds-button
            >
            <kds-message-alert type="warning" hideClose="true" icon-size="xs" v-if="licenseDownloadError">Unauthorized to download the license - Contact our support for more information</kds-message-alert>    
        </div>

        <div
            class="short-info"
            v-bind:class="{
                open: licenseDetailsVisible
            }"
        >
            <div class="open-btn">
                <span
                    @click="toggleLicenseDetailsVisible"
                    class="material-icons-outlined"
                >
                    {{
                        licenseDetailsVisible
                            ? 'arrow_drop_down'
                            : 'arrow_right'
                    }}
                </span>
            </div>
            <div
                class="valid-state kds-m-1"
                v-bind:class="[
                    { valid: licenseValid },
                    { warning: validDays < 30 && validDays > 0},
                    {
                        open: licenseDetailsVisible
                    }
                ]"
            >
                {{ licenseValid ? 'VALID' : 'INVALID' }}

                <b v-if="validDays < 30 && validDays > 0">({{ validDays }}{{ licenseDetailsVisible ? ' days left' : '' }})</b>
            </div>

            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                License Number:
                <div class="kds-subtitle2">
                    {{
                        licenseObj && licenseObj.license && licenseObj.license.number
                            ? licenseObj.license.number
                            : ''
                    }}
                </div>
            </div>
            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                License Owner:
                <div class="kds-subtitle2">
                    {{
                        licenseObj &&
                        licenseObj.owner &&
                        licenseObj.owner.person
                            ? licenseObj.owner.person
                            : ''
                    }}
                </div>
            </div>
            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                Application Name:
                <div class="kds-subtitle2">
                    {{
                        licenseObj && licenseObj.app && licenseObj.app.name
                            ? licenseObj.app.name
                            : ''
                    }}
                </div>
            </div>

            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                Version:
                <div class="kds-subtitle2">
                    {{
                        licenseObj && licenseObj.app && licenseObj.app.version
                            ? licenseObj.app.version
                            : ''
                    }}
                </div>
            </div>

            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                Issue Date:
                <div class="kds-subtitle2">
                    {{
                        licenseObj && licenseObj.license && licenseObj.license.issueDate
                            ? licenseObj.license.issueDate.split('T')[0]
                            : ''
                    }}
                </div>
            </div>

            <div
                class="kds-caption kds-p-2 fl3"
                v-bind:class="{
                    hidden: licenseDetailsVisible
                }"
            >
                Valid Date:
                <div class="kds-subtitle2">
                    {{
                        licenseObj && licenseObj.license && licenseObj.license.validDate
                            ? licenseObj.license.validDate.split('T')[0]
                            : ''
                    }}
                </div>
            </div>
        </div>

        <p class="download-info"      v-bind:class="{
                hidden: (!licenseDetailsVisible || (globalState.isAdmin || licenseInfo.userIsOwner))
            }">
        <kds-message-alert type="info" hideClose="true" icon-size="xs" v-if="!(globalState.isAdmin || licenseInfo.userIsOwner)">To download this license, ask the license owner or our support.</kds-message-alert>    
        </p>

        <div
            class="license-details"
            v-bind:class="{
                hidden: !licenseDetailsVisible
            }"
        >
            <table
                class="kds-table kds-table_alternate"
                v-for="(value, name) in licenseObj"
                :key="value"
                aria-label="License Details"
            >
                <thead>
                    <th class="head kds-subtitle2" colspan="2">{{ name }}</th>
                </thead>

                <tbody>
                    <tr class="child" v-for="(v2, n2) in value" :key="v2">
                        <td class="key kds-caption">{{ n2 }}</td>
                        <td class="value kds-caption">
                            {{ v2 === 'true' || v2 === 'false' ? '' : v2 }}
                            <span v-if="v2 === 'true'">
                                <span class="material-icons-outlined">
                                    done
                                </span>
                            </span>
                            <span v-if="v2 === 'false'">
                                <span class="material-icons-outlined">
                                    close
                                </span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br style="clear: both" />
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import licenseHandler from '@/lib/licenseHandler';

export default {
    data() {
        return {
            globalState: store.state,
            node_id: null,
            licenseValid: null,
            licenseObj: null,
            licenseDetailsVisible: false,
            licenseDownloadError: false
        };
    },
    name: 'LscLicense',
    props: {
        licenseInfo: Object
    },
    mounted: function () {
        if (this.licenseInfo !== undefined) {
            this.licenseValid =
                new Date(this.licenseInfo.valid_date) > new Date();
        }
        this.licenseParser();

        if (this.licenseInfo.id === this.$route.params.elementId) {
            this.toggleLicenseDetailsVisible();
        }
    },
    computed: {
        validDays() {
            return parseInt(Math.round((new Date(this.licenseInfo.valid_date) - new Date()) / 1000 / 60 / 60 / 24));
        }
    },
    methods: {
        deleteLicense() {
            if (confirm('do you really want to delete this license')) {
                licenseHandler
                    .deleteLicense(this.licenseInfo.id)
                    .then((delLicense) => {
                        console.log(delLicense);
                        this.emitter.emit('lsc:event:newLicenseAdded');
                    });
            }
        },
        downloadLicense() {
            // get license content from endpoint
            licenseHandler.getLicensesById(this.licenseInfo.id).then((data) => {
                const licenseContent = data.data.content
                const tmpLicenseDownload = document.createElement("a");
                const file = new Blob(
                    [
                    licenseContent
                    ],
                    {
                        type: "text/plain",
                    }
                );
            tmpLicenseDownload.href = URL.createObjectURL(file);
            tmpLicenseDownload.download = licenseContent.includes("signatureDigest") ? "license.signed" : "license.txt";
            document.body.appendChild(tmpLicenseDownload);
            tmpLicenseDownload.click();
            }).catch(() => {
                this.licenseDownloadError = true;
            })
        },
        licenseParser() {
            const licenseLines = this.licenseInfo.content.split('\n');

            const res = {};

            licenseLines.map((line) => {
                const kv = line.split('=');

                const parentKey = kv[0].split('.')[0].replace(/[\W_]+/g, ' ');
                if (parentKey !== '') {
                    let key = kv[0].replace(`${parentKey}.`, '');
                    key = key !== undefined ? key.trim() : key;

                    const value = kv[1] !== undefined ? kv[1].trim() : kv[1];

                    if (typeof res[parentKey] !== 'object') {
                        res[parentKey] = {};
                    }

                    res[parentKey][key] = value;
                }
            });

            // do some conversion for support of older file types
            if (res.license === undefined && res.lic !== undefined) {
                res.license = res.lic;
                delete res.lic;
            }

            // newer license files have a big module object that is split up here
            if (res.module !== undefined) {
                for (const [key, value] of Object.entries(res.module)) {
                     if (key.includes('.')) {
                        const [parentKey, childKey] = key.split('.');
                        if (res[parentKey] === undefined) {
                            res[parentKey] = {};
                        }
                        res[parentKey][childKey] = value;
                        delete res.module[key];
                    }
                }
            }

            this.licenseObj = res;
        },
        toggleLicenseDetailsVisible() {
            this.licenseDetailsVisible = !this.licenseDetailsVisible;
            
            if (this.licenseDetailsVisible) {
                // set the ROUTER to this Element
                this.$router.replace({params: {elementId: this.licenseInfo.id}});
            } else {
                if (this.$route.params.elementId === this.licenseInfo.id) {
                     this.$router.replace({params: {elementId: undefined}});
                }
            }

        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-license {
    padding: 0.5rem;
    background-color: var(--kds-color-atmo3);
    margin: 1rem;
    box-shadow: 4px 3px 4px var(--kds-color-atmo5);
}

.short-info {
    display: grid;
    grid-template-columns: min-content 0.4fr 0.8fr 0.8fr 0.8fr 0.5fr 0.5fr 0.5fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-template-areas: 'toggle validstate . . . . . .';
}

.short-info.open {
    grid-template-columns: min-content 1fr;
    grid-template-areas: 'toggle validstate';
}

.license-details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.license-details.hidden {
    display: none;
}

.lsc-license > div > table {
    width: 30%;
    margin: 1rem 1rem;
    box-sizing: border-box;
}

.license-details thead th {
    background-color: var(--kds-color-atmo4);
}

.valid-state {
    background-color: var(--kds-color-sema2);
    color: var(--kds-color-atmo1);
    padding: 0.25rem;
    float: left;
    width: 5rem;
    text-align: center;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    grid-area: "validstate";
    border-radius: 0.25rem;
}

.valid-state.open {
    float: none;
    width: auto;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.valid-state.valid {
    background-color: var(--kds-color-sema3);
}

.valid-state.valid.warning {
    background-color: var(--kds-color-sema1);
}

.option-bar {
    display: flex;
    justify-content: flex-start;
}

.option-bar > kds-message-alert {
    margin: .5rem;
}

.fl3.hidden {
    display: none;
}

.open-btn {
    cursor: pointer;
    width: 2rem;
    display: block;
    height: 2rem;
    float: left;
    grid-area: "toggle";
}

.open-btn > span {
    height: 3rem;
}

.download-info {
    margin: 0.5rem 0rem;
}

table td {
    padding: .25rem;
}
</style>

