<template>
    <div class="edit-build">
        <LscPopup v-bind:popupOpen="popupOpen" v-bind:on-close="close">
            <template v-slot:header>Edit Build</template>
            <template v-slot:content>
                <kds-message-alert
                    class="kds-m-1"
                    v-if="globalState.isAdmin && linkedNodes?.length > 1"
                    type="info"
                    hide-close
                    >Attention this build is linked to other nodes - keep this
                    in mind when changing documents and other
                    informations.</kds-message-alert
                >

                <kds-dropdown
                    :value="[buildInfo.product_name]"
                    @input="form.product_name = $event.target.value[0]"
                    class="kds-mt-3"
                    label="Product Name"
                    placeholder="Make selection"
                    name="product_name"
                    :options="dropdownValues"
                ></kds-dropdown>

                <kds-input
                    label="Product Version"
                    name="version_product"
                    :value="buildInfo.version_product"
                    @input="form.version_product = $event.target.value"
                ></kds-input>
                <kds-input
                    label="Project Version"
                    name="version_project"
                    :value="buildInfo.version_project"
                    @input="form.version_project = $event.target.value"
                ></kds-input>

                <div class="kds-subtitle2">Release Date</div>
                <input
                    type="date"
                    :value="buildInfo?.release_date?.split('T')[0]"
                    @input="form.release_date = $event.target.value"
                />

                <kds-checkbox
                    class="kds-m-5"
                    label="is Expired"
                    value="expired"
                    :checked="buildInfo.is_expired ? '' : null"
                    @input="form.is_expired = $event.target.isChecked"
                ></kds-checkbox>

                <kds-checkbox
                    class="kds-m-5"
                    label="is Release"
                    value="release"
                    :checked="buildInfo.is_release ? '' : null"
                    @input="form.is_release = $event.target.isChecked"
                ></kds-checkbox>
            </template>
            <template v-slot:footer>
                <kds-button
                    color="tertiary"
                    @click="editBuild"
                    v-bind:class="{
                        hidden: !globalState.isAdmin
                    }"
                    class="kds-m-2 add-document"
                    >Edit Build</kds-button
                ></template
            >
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import buildHandler from '@/lib/buildHandler';

import LscPopup from '@/components/LscStructure/LscPopup.vue';

export default {
    data() {
        return {
            globalState: store.state,
            dropdownValues: [
                { value: 'Lion', label: 'Lion' },
                { value: 'Lion.Connect.', label: 'Lion.Connect.' },
                { value: 'Lion.Detect.', label: 'Lion.Detect.' },
                { value: 'Lion.Design.', label: 'Lion.Design.' },
                { value: 'Lion.AR.', label: 'Lion.AR.' }
            ],
            form: {
                product_name: 'Lion',
                file_path: '',
                version_product: 'V 1.0.0',
                version_project: 'V 0.0.1',
                size: 0,
                is_expired: false,
                is_release: true,
                md5: '',
                mimetype: '',
                release_date: ''
            },
        };
    },
    name: 'LscEditBuild',
    components: {
        LscPopup
    },
    props: {
        popupOpen: Boolean,
        onClose: Function,
        buildInfo: Object,
        linkedNodes: Array
    },
    created: function () {},
    computed: {
        build_id() {
            return this.buildInfo.NodeBuilds[0].build_id;
        }
    },
    methods: {
        refreshFormData() {
            this.form.is_expired = this.buildInfo.is_expired;
            this.form.is_release = this.buildInfo.is_release;
            this.form.release_date = this.buildInfo.release_date;
        },
        editBuild() {
            buildHandler
                .editBuild(this.build_id, this.form)
                .then(() => {
                    this.close();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        close() {
            this.emitter.emit('lsc:event:newBuildAdded');
            this.onClose();
        }
    },
    watch: {
        popupOpen: function (newVal, oldVal) {
            if (oldVal == false && newVal !== oldVal) {
                this.refreshFormData();
            }
        }
    }
};
</script>

<style scoped></style>
