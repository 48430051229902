const { dfRq } = require('./endpointConnector');

function getChildren(currentNode) {
    return dfRq({
        method: "get",
        urlPath: `/nodes/${currentNode}/children`
    }, {}, {}, true);
}

function getSpecific(nodeId) {
    return dfRq({
        method: "get",
        urlPath: `/nodes/${nodeId}`
    }, {}, {}, true);

}

function createNode(currentNode, currentType, formData) {
    formData.type = currentType === '' ? "ENTERPRISE" : currentType === "ENTERPRISE" ? "SITE" : "LINE";
    formData.parent_id = currentNode;

    return dfRq({
        method: "put",
        urlPath: `/nodes/`
    }, {}, formData, true);
}

function editNode(currentNode, formData) {
    formData.id = currentNode;
    return dfRq({
        method: "patch",
        urlPath: `/nodes/`
    }, {}, formData, true);
}

function deleteNode(currentNode) {
    return dfRq({
        method: "delete",
        urlPath: `/nodes/${currentNode}`
    }, {}, {}, true);
}

function allEnterprises() {
    return dfRq({
        method: "get",
        urlPath: `/nodes/all/enterprise`,
    }, {}, {}, true);
}

function allNodes() {
    return dfRq({
        method: "get",
        urlPath: `/nodes/all/nodes`,
    }, {}, {}, true);
}

function rearrangeNodes(objectData) {
    const formData = {};
    formData.nodeList = JSON.stringify(objectData);

    return dfRq({
        method: "post",
        urlPath: `/nodes/rearrange`,
    }, {}, formData, true);
}

module.exports = {
    getChildren,
    getSpecific,
    createNode,
    editNode,
    deleteNode,
    allEnterprises,
    allNodes,
    rearrangeNodes
};