<template>
    <div
        class="build"
        v-bind:class="{
            user: !globalState.isAdmin,
            expired: buildInfo.is_expired
        }"
    >
        <div
            class="
                version-product
                build-head
                kds-bold kds-uppercase kds-subtitle1
            "
        >
            <button class="icon-button" v-on:click="toggleBuildOpen()">
                <span class="material-icons-outlined md-18">
                    {{ buildOpen ? 'arrow_drop_down' : 'arrow_right' }}
                </span>
            </button>

            {{ buildInfo.version_product }}
        </div>
        <div class="product-name">
            <span class="product-badge">{{ buildInfo.product_name }}</span>
        </div>
        <div class="create-date kds-subtitle1">
            {{
                buildInfo.release_date != '' && buildInfo.release_date
                    ? buildInfo.release_date.split('T')[0]
                    : ''
            }}
        </div>
        <div class="is-release build-head kds-bold kds-uppercase kds-subtitle1">
            {{ buildInfo.is_release ? 'RELEASE' : 'RELEASE_CANDIDATE' }}
            <b v-if="globalState.isAdmin">
                [ {{ !buildInfo.is_on_srv ? 'PIPELINE' : 'UPLOADED' }} ]
            </b>
        </div>
        <div class="node-build-state" v-if="globalState.isAdmin">
            <span class="kds-uppercase kds-subtitle1">
                {{ (node_build_state === 'NEW') ? "PREPARATION" : node_build_state }}
            </span>

            <kds-button
                color="tertiary"
                v-bind:class="{
                    hidden: !globalState.isAdmin || node_build_state === 'NEW'
                }"
                @click="lastState"
                >            
                {{ node_build_state === 'ARCHIVED' ? "Make Available" : "Back to Preparation" }}

            </kds-button>

            <kds-button
                color="secondary"
                v-bind:class="{
                    hidden:
                        !globalState.isAdmin || node_build_state === 'ARCHIVED'
                }"
                @click="nextState"
            >
            {{ node_build_state === 'NEW' ? "Make Available" : "To Archive" }}
            </kds-button>
        </div>
        <div class="download build-head">
            <span v-if="downloadInProgress === true">
                {{
                    globalState.progress[downloadProgresssId]
                        ? globalState.progress[downloadProgresssId]
                        : 0
                }}
                %
            </span>

            <button
                v-on:click="download()"
                v-bind:class="{ spin: downloadInProgress === true }"
            >
                <span class="material-icons-outlined md-18">
                    {{
                        downloadInProgress === '404'
                            ? 'sync_problem'
                            : downloadInProgress
                            ? 'sync'
                            : 'file_download'
                    }}
                </span>
            </button>
        </div>
        <div
            class="details"
            v-bind:class="{
                hidden: !buildOpen
            }"
        >
            <div class="details-content">
                <kds-message-alert class="kds-m-1"  v-if="buildInfo.is_expired" type="error" hide-close>Attention this build is expired! Expired means, that a newer version with e.g. a bugfix is available that should be used instead.</kds-message-alert>
                <kds-message-alert class="kds-m-1"  v-if="globalState.isAdmin && linkedNodes.length > 1" type="info" hide-close>Attention this build is linked to other nodes - keep this in mind when changing documents and other informations.</kds-message-alert>

                <div class="option-bar">
                    <kds-button
                        color="tertiary"
                        @click="
                            () => {
                                popups.editBuild = !popups.editBuild;
                            }
                        "
                        class="kds-m-1"
                        v-bind:class="{
                            hidden: !globalState.isAdmin
                        }"
                        >Edit Build</kds-button
                    >
                    <kds-button
                        color="tertiary"
                        @click="
                            () => {
                                popups.linkBuild = !popups.linkBuild;
                                loadNodes();
                            }
                        "
                        class="kds-m-1"
                        v-bind:class="{
                            hidden: !globalState.isAdmin
                        }"
                        >Link Build</kds-button
                    >
                    <kds-button
                        color="tertiary"
                        @click="deleteBuild()"
                        class="kds-m-1"
                        v-bind:class="{
                            hidden: !globalState.isAdmin
                        }"
                        >Delete Build</kds-button
                    >
                </div>
                <kds-tab>
                    <kds-tab-title slot="title">Documents</kds-tab-title>
                    <kds-tab-panel slot="panel">
                        <div class="documents">
                            <draggable
                                v-model="documents"
                                v-bind="dragOptions"
                                group="documents"
                                @start="drag = true"
                                @end="drag = false"
                                item-key="id"
                                :disabled="!globalState.isAdmin"
                                @change="documentsRearranged"
                            >
                                <template #item="{ element }">
                                    <div>
                                        <LscDocument
                                            v-bind:documentInfo="element"
                                        />
                                    </div>
                                </template>
                            </draggable>
                            <LscNoContent v-bind:dataArray="documents">
                                <template v-slot:header-all
                                    >No Documents</template
                                >

                                <template v-slot:content-admin
                                    >Add the first document to this
                                    Build</template
                                >
                                <template v-slot:content-user
                                    >No documents are currently added to this
                                    Build</template
                                >
                                <template v-slot:action-all> </template>
                            </LscNoContent>
                            <LscLoadingSpinner
                                v-if="loadingSpinner.documents"
                                class="kds-m-5"
                            />

                            <div class="centered">
                                <kds-button
                                    color="tertiary"
                                    @click="
                                        () => {
                                            popups.addDocumentOpen =
                                                !popups.addDocumentOpen;
                                        }
                                    "
                                    v-bind:class="{
                                        hidden: !globalState.isAdmin
                                    }"
                                    class="kds-m-2 add-document"
                                    >Add Document</kds-button
                                >
                            </div>

                            <LscPopup
                                v-bind:popupOpen="popups.addDocumentOpen"
                                v-bind:on-close="
                                    () => {
                                        popups.addDocumentOpen =
                                            !popups.addDocumentOpen;
                                    }
                                "
                            >
                                <template v-slot:header>Add Document</template>
                                <template v-slot:content>
                                    <LscCreateDocument
                                        v-bind:buildId="buildInfo.id"
                                /></template>
                            </LscPopup></div
                    ></kds-tab-panel>
                    <kds-tab-title slot="title">Meta</kds-tab-title>
                    <kds-tab-panel slot="panel">
                        <div class="meta">
                            <div v-for="meta in metaData" :key="meta.key">
                                <kds-tooltip class="info tooltip-inline" v-if="globalState.isAdmin || meta.public === true" :disableTooltip="meta.description === undefined">
                                    <div class="name kds-subtitle2">
                                        {{ meta.name }}
                                    </div>
                                    <div class="description kds-caption">
                                        {{ buildInfo[meta.key] }}
                                    </div>
                                    <div slot="tooltip-title">
                                        {{ meta.name }}
                                    </div>
                                    <div slot="tooltip">
                                        {{ meta.description }}
                                    </div>
                                </kds-tooltip>
                            </div>
                        </div>
                    </kds-tab-panel>
                    <kds-tab-title
                        slot="title"
                        v-bind:class="{ hidden: !globalState.isAdmin }"
                        >Download History</kds-tab-title
                    >
                    <kds-tab-panel slot="panel">
                        <div class="dl-hist">
                            <div class="dl-info-bar">
                                <div>
                                    <div class="kds-h6">Last Download</div>
                                    <div class="kds-h2">
                                        {{ getLatestDownloadTime() }}
                                    </div>
                                </div>
                                <div>
                                    <div class="kds-h6">First Download</div>
                                    <div class="kds-h2">
                                        {{ getFirstDownload() }}
                                    </div>
                                </div>

                                <div>
                                    <div class="kds-h6">Download Count</div>
                                    <div class="kds-h2">
                                        {{
                                            downloadHistory &&
                                            downloadHistory.length
                                        }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    class="dl-hist"
                                    v-for="dlhist in downloadHistory"
                                    :key="dlhist"
                                >
                                    <div class="dl-hist-user">
                                        <div class="name kds-subtitle2">
                                            {{ dlhist.username }}
                                        </div>
                                        <div class="description kds-caption">
                                            {{ dlhist.download_date }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </kds-tab-panel>
                </kds-tab>

                <LscLinkBuild 
                    v-bind:popupOpen="popups.linkBuild" 
                    v-bind:on-close="
                        () => {
                            popups.linkBuild = !popups.linkBuild;
                        }
                    "
                    v-bind:buildInfo="buildInfo"
                >
                </LscLinkBuild>

                <LscEditBuild 
                    v-bind:popupOpen="popups.editBuild" 
                    v-bind:on-close="
                        () => {
                            popups.editBuild = !popups.editBuild;
                        }
                    "
                    v-bind:buildInfo="buildInfo"
                    v-bind:linkedNodes="linkedNodes ? linkedNodes : []"
                >
                </LscEditBuild>
            </div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import buildHandler from '@/lib/buildHandler';
import documentHandler from '@/lib/documentHandler';
import LscDocument from '@/components/LscDocument/LscDocument';
import LscEditBuild from '@/components/LscBuild/dialogs/LscEditBuild.vue';
import LscCreateDocument from '@/components/LscDocument/LscCreateDocument';
import LscPopup from '@/components/LscStructure/LscPopup.vue';
import LscNoContent from '@/components/LscStructure/LscNoContent.vue';
import LscLoadingSpinner from '@/components/LscStructure/LscLoadingSpinner.vue';
import LscLinkBuild from '@/components/LscBuild/dialogs/LscLinkBuild.vue';
import mime from 'mime-types';
import draggable from 'vuedraggable';

export default {
    data() {
        return {
            globalState: store.state,
            popups: {
                addDocumentOpen: false,
                editBuild: false,
                linkBuild: false
            },
            downloadHistory: [],
            linkedNodes: [],
            downloadInProgress: false,
            documents: null,
            buildOpen: false,
            metaData: [
                { public: true, key: 'id', name: 'Build Id' },
                { public: true, key: 'createdAt', name: 'Created at' },
                { public: true, key: 'is_expired', name: 'is Expired'},
                { public: true, key: 'is_release', name: 'is Release', description: "True if it is a release, otherwise it would be a release candidate." },
                { public: true, key: 'product_name', name: 'Product' },
                { public: true, key: 'size', name: 'Size in bytes' },
                { public: true, key: 'version_product', name: 'Product Version' },
                { public: true, key: 'version_project', name: 'Project Version' },
                { public: true, key: 'mimetype', name: 'Mimetype' },
                { public: false, key: 'is_on_srv', name: 'is own Upload', description: "True, if build is provided by own upload, otherwise it is from Nexus!" },
                { public: true, key: 'release_date', name: 'Release Date' }
            ],
            loadingSpinner: {
                documents: false
            },
            downloadProgresssId: null
        };
    },
    name: 'LscBuild',
    components: {
        LscDocument,
        LscEditBuild,
        LscCreateDocument,
        LscPopup,
        LscNoContent,
        LscLoadingSpinner,
        LscLinkBuild,
        draggable
    },
    props: {
        buildInfo: Object
    },
    created: function () {},
    computed: {
        node_build_state() {
            return this.buildInfo.NodeBuilds[0].state
        },
        node_id() {
            return this.buildInfo.NodeBuilds[0].node_id
        },
        build_id() {
            return this.buildInfo.NodeBuilds[0].build_id
        },
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    },
    mounted() {
        this.getAssociatedDocuments();
        this.emitter.on('lsc:event:newDocumentAdded', () => {
            this.popups.addDocumentOpen = false;
            if (this.buildOpen) {
                this.getAssociatedDocuments();
            }
        });

        this.emitter.on('lsc:event:buildLinkingChanged', (buildId) => {
            if (this.build_id === buildId) {
                this.loadNodes();
            }
        });
        this.loadNodes();

        this.getDownloadHistory();

        if (this.build_id === this.$route.params.elementId) {
            this.toggleBuildOpen();
        }
    },
    methods: {
        deleteBuild() {
            if (
                confirm(
                    'do you really want to delete this build - it could also be part of other nodes'
                )
            ) {
                buildHandler.deleteBuild(this.buildInfo.id).then(() => {
                    this.emitter.emit('lsc:event:newBuildAdded');
                });
            }
        },
        getLatestDownloadTime() {
            if (this.downloadHistory && this.downloadHistory.length) {
                let latestDownload = new Date(
                    this.downloadHistory[0].download_date
                ).getTime();

                return this.calculateDownloadDiff(latestDownload);
            } else {
                return 'no Download';
            }
        },
        getFirstDownload() {
            if (this.downloadHistory && this.downloadHistory.length) {
                let latestDownload = new Date(
                    this.downloadHistory[
                        this.downloadHistory.length - 1
                    ].download_date
                ).getTime();

                return this.calculateDownloadDiff(latestDownload);
            } else {
                return 'no Download';
            }
        },
        calculateDownloadDiff(latestDownload) {
            let timediff = (new Date().getTime() - latestDownload) / 1000;

            return timediff < 60 * 10
                ? 'less than 10 Minutes'
                : timediff < 60 * 60
                ? 'less than one Hour'
                : timediff < 60 * 60 * 24
                ? 'less than a Day'
                : timediff < 60 * 60 * 24 * 7
                ? 'less than a Week'
                : timediff < 60 * 60 * 24 * 7 * 31
                ? 'less than a Month'
                : 'more than a Month';
        },
        toggleBuildOpen() {
            this.buildOpen = !this.buildOpen;

            if (this.buildOpen) {
                // set the ROUTER to this Element
                this.$router.replace({ params: { elementId: this.build_id } });
            } else {
                if (this.$route.params.elementId === this.build_id) {
                    this.$router.replace({ params: { elementId: undefined } });
                }
            }
        },
        getAssociatedDocuments() {
            this.loadingSpinner.documents = true;
            documentHandler
                .getDocumentsForBuild(this.build_id)
                .then((response) => {
                    this.documents = response.data;
                    this.loadingSpinner.documents = false;
                });
        },
        documentsRearranged() {
            documentHandler
                .rearrangeDocuments(this.documents)
                .then(() => {})
                .catch(function (error) {
                    console.log(error);
                });
        },
        getDownloadHistory() {
            if (this.globalState && this.globalState.isAdmin) {
                buildHandler
                    .getDownloadHistoryForBuild(this.build_id)
                    .then((response) => {
                        this.downloadHistory = response.data;
                    });
            }
        },
        nextState() {
            const nextStateOfBuild =
                this.node_build_state === 'NEW' ? 'AVAILABLE' : 'ARCHIVED';
            this.changeState(nextStateOfBuild);
        },
        lastState() {
            const nextStateOfBuild =
                this.node_build_state === 'ARCHIVED' ? 'AVAILABLE' : 'NEW';
            this.changeState(nextStateOfBuild);
        },
        changeState(nextStateOfBuild) {
            buildHandler
                .changeState(this.build_id, this.node_id, nextStateOfBuild)
                .then((newStateResp) => {
                    if (newStateResp.status === 200) {
                        this.emitter.emit('lsc:event:newBuildAdded');
                    }
                });
        },
        loadNodes() {
            if (this.globalState.isAdmin) {
                return buildHandler
                .getLinkedNodes(this.build_id)
                .then((response) => {
                    this.linkedNodes = response.data;
                    let found = false;
                    this.linkedNodes.forEach(ln => {
                        if (ln.node_id === this.node_id) {
                            found = true;
                        }
                    })
                    if (!found) {
                        this.emitter.emit('lsc:event:newBuildAdded');
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        },
        download() {
            this.downloadInProgress = true;
            this.downloadProgresssId = this.buildInfo.id;

            buildHandler
                .downloadBuild(this.buildInfo.id, this.downloadProgresssId)
                .then((response) => {

                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    const extension =
                        this.buildInfo.file_path.split('.')[
                            this.buildInfo.file_path.split('.').length - 1
                        ];

                    // DEFINE file ending
                    const fileExtension = extension
                        ? extension
                        : mime.extension(this.buildInfo.mimetype);

                    link.download = `${this.buildInfo.product_name}_${this.buildInfo.version_product}_${this.buildInfo.version_project}.${fileExtension}`;
                    link.click();
                    this.downloadInProgress = false;
                    this.getDownloadHistory();
                })
                .catch((err) => {
                    console.log(err);
                    this.downloadInProgress = '404';
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


/* Tooltip Styles */
kds-tooltip {
    cursor: pointer;
}

kds-tooltip[disable-tooltip] {
    cursor: auto;
}

.tooltip-inline {
    display: inline-block;
}
/* DRAG STYLES */

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.spin {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

button.next-state {
    padding: 0.5rem;
    background: var(--kds-color-atmo5);
    border-radius: 0.5rem;
    border: none;
    box-shadow: 4px 3px 4px var(--kds-color-atmo5);
    cursor: pointer;
    margin: 0 0.7rem;
}

button.next-state.hidden {
    display: none;
}

.build {
    display: grid;
    grid-template-columns: 2fr 0.5fr 2fr 4fr 4fr 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'version-product product-name create-date is-release node-build-state download'
        'details details details details details details';
    background-color: var(--kds-color-atmo4);
    margin: 1rem;
    box-shadow: 4px 4px 6px #b5b5b5;
}

.build.expired {
    border-top: 4px solid var(--kds-color-sema2);
}

.build.user {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr 2fr 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'version-product product-name create-date is-release download'
        'details details details details details';
    background-color: var(--kds-color-atmo4);
    margin: 1rem;
    box-shadow: 4px 4px 6px #b5b5b5;
}

.build > div {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.build-head {
    font-weight: bold;
}

.version-product {
    grid-area: version-product;
}

.product-name {
    grid-area: product-name;
}

.create-date {
    grid-area: create-date;
}

.product-badge {
    color: var(--kds-color-base1);
    background-color: var(--kds-color-acce2h);
    border-radius: 15px;
    padding: 0.25rem 0.5rem;
    font-size: 0.8rem;
    margin: 0;
}

.is-release {
    grid-area: is-release;
}

.node-build-state {
    grid-area: node-build-state;
}

.download {
    grid-area: download;
}

.download > button {
    border: none;
    background: none;
    cursor: pointer;
}

div.details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: min-content 4fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'details-navbar details-navbar details-navbar'
        'details-content details-content details-content';
    grid-area: details;
    background-color: var(--kds-color-atmo3);
}

.details-navbar {
    grid-area: details-navbar;
    text-align: left;
}

.details-content {
    grid-area: details-content;
}

.details.hidden {
    display: none;
}

.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'name'
        'description';

    float: left;
    text-align: left;
    width: 30%;
    margin: 0.5rem;
}

.name {
    grid-area: name;
    font-weight: bold;
}

.description {
    grid-area: description;
    font-size: 0.8rem;
}

.dl-info-bar {
    display: flex;
    justify-content: space-around;
    text-align: left;
}

.dl-hist-user {
    display: flex;
    justify-content: space-between;
    margin: 1rem 15rem;
}

kds-tab .hidden {
    display: none;
}

.option-bar {
    display: flex;
    justify-content: flex-start;
}

.build-is-expired {
    background-color: var(--kds-color-sema2);
    color: var(--kds-color-atmo1);
    padding: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    border-radius: 5px;
}
</style>

<style>
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
}
</style>
