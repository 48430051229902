<template>
    <div class="news">
        <div class="add-news" v-bind:class="{ hidden: !globalState.isAdmin }">
            <kds-tab @panel-change="tabChanged">
                <kds-tab-title slot="title">General</kds-tab-title>
                <kds-tab-panel slot="panel">
                    <kds-input
                        label="Title"
                        name="title"
                        :value="form.title"
                        @input="form.title = $event.target.value"
                    ></kds-input>

                    <kds-checkbox
                        class="kds-m-3"
                        label="Public visible on Front Page"
                        value="pubvis"
                        :checked="form.public_visible ? '' : null"
                        @input="form.public_visible = $event.target.isChecked"
                    ></kds-checkbox>

                    <img
                        class="main-picture-preview"
                        :src="
                            newsObj && newsObj.main_picture
                                ? pictureUploadsUrl + newsObj.main_picture
                                : null
                        "
                    />

                    <div class="upload-desc kds-subtitle2">Picture Upload</div>
                    <div class="upload-inputs">
                        <div>
                            <input type="file" v-on:change="onFileChange" />
                        </div>
                        <kds-button
                            color="tertiary"
                            class="kds-m-2"
                            @click="
                                () => {
                                    loadPictures();
                                    addUrlToMainPicture = true;
                                    popups.browsePictures =
                                        !popups.browsePictures;
                                }
                            "
                            >Browse Pictures</kds-button
                        >
                    </div>
                </kds-tab-panel>
                <kds-tab-title slot="title">Text</kds-tab-title>
                <kds-tab-panel slot="panel">
                    <div class="news-editor">
                        <div class="news-editor-options">
                            <kds-button
                                color="tertiary"
                                @click="
                                    () => {
                                        popups.addPicture = !popups.addPicture;
                                    }
                                "
                                class="kds-m-2 add-picture"
                                >Add Picture</kds-button
                            >

                            <kds-button
                                color="tertiary"
                                @click="
                                    () => {
                                        loadPictures();
                                        addUrlToMainPicture = false;
                                        popups.browsePictures =
                                            !popups.browsePictures;
                                    }
                                "
                                class="kds-m-2 browse-pictures"
                                >Browse Pictures</kds-button
                            >
                        </div>
                        <div class="news-text-input">
                            <textarea
                                :value="form.content"
                                @input="form.content = $event.target.value"
                            ></textarea>
                        </div>
                        <div class="news-preview">
                            <Markdown :source="form.content" />
                        </div>
                    </div>
                </kds-tab-panel>
            </kds-tab>

            <div class="dialog-save">
                <!--
                <kds-button
                    color="tertiary"
                    @click="publishAndSave()"
                    class="kds-m-1 add-picture"
                    >Publish</kds-button
                >
                -->

                <kds-button @click="saveNewsArticle()" class="kds-m-1"
                    >Save</kds-button
                >
            </div>

            <LscPopup
                v-bind:popupOpen="popups.browsePictures"
                v-bind:on-close="
                    () => {
                        popups.browsePictures = !popups.browsePictures;
                    }
                "
            >
                <template v-slot:header>Browse Pictures</template>
                <template v-slot:content>
                    <div class="browse-pictures-container">
                        <div
                            @click="choosePicture"
                            class="browse-picture-choose"
                            v-for="pic in availablePictures"
                            :key="pic"
                        >
                            <img
                                class="browse-picture-preview"
                                :src="pictureUploadsUrl + '/' + pic"
                            />
                        </div>
                    </div>
                </template>
            </LscPopup>

            <LscPopup
                v-bind:popupOpen="popups.addPicture"
                v-bind:on-close="
                    () => {
                        popups.addPicture = !popups.addPicture;
                    }
                "
            >
                <template v-slot:header>Add Picture to Article</template>
                <template v-slot:content>
                    <div class="upload-desc kds-subtitle2">Picture Upload</div>
                    <div class="upload-inputs">
                        <div>
                            <input
                                type="file"
                                v-on:change="onArticleFileChange"
                            />
                        </div>
                        <kds-button
                            class="kds-m-2"
                            @click="uploadArticlePicture"
                            :disabled="!selectedArticleFile"
                            >Upload Picure</kds-button
                        >
                    </div>
                </template>
            </LscPopup>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import { pictureUploadsUrl } from '@/config/config';
import newsHandler from '@/lib/newsHandler';
import LscPopup from './../LscStructure/LscPopup.vue';
import Markdown from 'vue3-markdown-it';

export default {
    components: {
        Markdown,
        LscPopup
    },
    data() {
        return {
            globalState: store.state,
            selectedFile: '',
            selectedArticleFile: '',
            popups: {
                addPicture: false,
                browsePictures: false
            },
            form: {
                node_id: null,
                content: `Your Text`,
                title: 'My Title',
                main_picture: '/dlmeme.png',
                state: 'EDITING',
                public_visible: false
            },
            pictureUploadsUrl: '',
            availablePictures: [],
            addUrlToMainPicture: false
        };
    },
    name: 'LscCreateNews',
    props: {
        nodeId: String,
        newsObj: Object,
        onClose: Function
    },
    mounted: function () {
        this.pictureUploadsUrl = pictureUploadsUrl;

        if (this.newsObj) {
            this.form.id = this.newsObj.id;
            this.form.content = this.convertImagesForFrontend(
                this.newsObj.content
            );
            this.form.title = this.newsObj.title;
            this.form.main_picture = this.newsObj.main_picture;
            this.form.state = this.newsObj.state;
            this.form.public_visible = this.newsObj.public_visible;
        }
    },
    methods: {
        choosePicture(e) {
            console.log(e);

            console.log(e.target.querySelector('img').src);

            if (this.addUrlToMainPicture) {
                this.form.main_picture = this.convertImagesForMainPicture(
                    e.target.querySelector('img').src
                );
            } else {
                this.form.content = `${this.form.content} \n ![Picture desc](${
                    e.target.querySelector('img').src
                })`;
            }

            this.popups.browsePictures = false;
        },
        loadPictures() {
            newsHandler.getPicturesFromServer().then((res) => {
                console.log(res.data);

                this.availablePictures = res.data;
            });
        },
        onFileChange(e) {
            const selectedFile = e.target.files[0]; // accessing file
            this.selectedFile = selectedFile;
        },
        onArticleFileChange(e) {
            const selectedArticleFile = e.target.files[0]; // accessing file
            this.selectedArticleFile = selectedArticleFile;
        },
        uploadPicture(selectedFile) {
            return newsHandler
                .uploadPicture(selectedFile, this.onProgress)
                .then((res) => {
                    console.log(res.data);

                    let filename = `/${res.data.filename}`;
                    return filename;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        onProgress(p) {
            console.log(p);
        },
        uploadArticlePicture() {
            this.uploadPicture(this.selectedArticleFile).then((filename) => {
                this.form.content = `${this.form.content} \n ![Picture desc](${pictureUploadsUrl}${filename})`;

                this.popups.addPicture = false;
            });
        },
        uploadMainPicture() {
            console.log('uploadMainPicture');
            return this.uploadPicture(this.selectedFile)
                .then((res) => {
                    console.log(res);
                    this.form.main_picture = res;
                })
                .catch((err) => {
                    console.log(err);
                });
        },

        publishAndSave() {
            this.form.state = 'PUBLISHED';
            this.saveNewsArticle();
        },
        convertImagesForMainPicture(replacedOutText) {
            try {
                replacedOutText = replacedOutText
                    .split(pictureUploadsUrl)
                    .join('');
            } catch (e) {
                console.log(e);
            }
            return replacedOutText;
        },
        convertImagesForFrontend(replacedOutText) {
            try {
                replacedOutText = replacedOutText
                    .split('SERV_IMG_PATH')
                    .join(pictureUploadsUrl);
            } catch (e) {
                console.log(e);
            }
            return replacedOutText;
        },
        convertImagesForBackend(replacedOutText) {
            try {
                replacedOutText = replacedOutText
                    .split(pictureUploadsUrl)
                    .join('SERV_IMG_PATH');
            } catch (e) {
                console.log(e);
            }
            return replacedOutText;
        },
        saveNewsArticle() {
            let uploadProm = null;

            console.log('saveNewsArticle');

            if (this.selectedFile !== '') {
                uploadProm = this.uploadMainPicture();
            }

            Promise.all([uploadProm]).then((uploadPromRes) => {
                console.log(uploadPromRes);

                // reset upload file
                this.selectedFile = '';

                // convert back TODO
                this.form.content = this.convertImagesForBackend(
                    this.form.content
                );

                if (this.newsObj) {
                    newsHandler
                        .editNews(this.form)
                        .then((res) => {
                            console.log(res.data);
                            this.emitter.emit('lsc:event:newNewsAdded');
                            this.onClose();
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.form.content = this.convertImagesForFrontend(
                                this.form.content
                            );
                        });
                } else {
                    this.form.node_id = this.nodeId;

                    // save the article to a node
                    newsHandler
                        .createNews(this.nodeId, this.form)
                        .then((res) => {
                            console.log(res.data);
                            this.emitter.emit('lsc:event:newNewsAdded');
                            this.onClose();
                        })
                        .catch((err) => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.form.content = this.convertImagesForFrontend(
                                this.form.content
                            );
                        });
                }
            });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.news-editor {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'news-editor-options news-editor-options'
        'news-text-input news-preview';
    height: 500px;
}

.news-editor-options {
    grid-area: news-editor-options;
    display: flex;
    padding: 1rem 0;
}

.news-text-input {
    grid-area: news-text-input;
}

.news-text-input > textarea {
    width: 100%;
    height: 100%;
}

.news-preview {
    grid-area: news-preview;
    overflow-y: scroll;
    padding: 1rem;
}

.news-editor-bottom {
    grid-area: news-editor-bottom;
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}

.info {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'name'
        'description';

    float: left;
    font-size: 0.8rem;
    font-size: 0.8rem;
    text-align: left;
    width: 30%;
    margin: 0.5rem;
}

.info.upload {
    width: 100%;
}

.name {
    grid-area: name;
    font-weight: bold;
}

.description {
    grid-area: description;
    font-size: 0.8rem;
}

.progress-bar-cont {
    width: 100%;
    float: left;
}

.progress-bar {
    display: block;
    float: left;
    background-color: var(--kds-color-acce2);
    color: var(--kds-color-atmo1);
    font-weight: bold;
    padding: 0.5rem 0.2rem;
    border-radius: 0.25rem;
}

.main-picture-preview {
    display: block;
    max-width: 100%;
    max-height: 300px;
}

.upload-inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dialog-save {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.browse-pictures-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: 600px;
    overflow: scroll;
    position: relative;
}

.browse-picture-preview {
    width: 200px;
    height: 100px;
    object-fit: cover;
    display: block;
}

.browse-picture-choose:hover::after {
    content: 'Choose';
    display: flex;
    position: relative;
    height: 100px;
    top: -100px;
    width: 200px;
    background: #0000005c;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.browse-picture-choose {
    margin: 1rem;
    height: 100px;
}
</style>
