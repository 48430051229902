<template>
    <div class="lsc-navbar">
        <div class="navbar-content">
            <img
                alt="Körber Logo"
                src="@/assets/koerber_logo_rgb_white.0763d983.svg"
            />
            <div class="user-info kds-caption">
                <a :href="this.globalStore.accountConfigUrl" target="_blank">
                    <span class="material-icons-outlined md-18">
                        account_circle
                    </span>
                </a>
                {{ globalStore.username }}
                <button v-on:click="logoutUser()">
                    <span class="material-icons-outlined md-18"> logout </span>
                </button>
            </div>
            <div class="nav-item kds-caption">Lion Service Center</div>
        </div>
        <div class="support kds-caption">
            <div class="support-icon">
                <span class="material-icons-outlined md-18">
                    support_agent
                </span>
            </div>
            <div class="mail">support.lion@koerber.com</div>
            <div class="phone"></div>
        </div>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
export default {
    data() {
        return {
            globalStore: store.state,
        };
    },
    name: 'LscNavBar',
    props: {},
    created: function () {
        this.getUserInfo();
    },
    methods: {
        getUserInfo() {
            if (this.keycloak.authenticated) {
                this.keycloak.loadUserProfile().then((res) => {
                    // console.log(res);
                    res.is_admin = this.keycloak.resourceAccess['lsc-server'] ? this.keycloak.resourceAccess['lsc-server'].roles.includes("lsc-admin") : false;
                    store.setUser(res);
                });
            } else {
                console.log('not authenticated');
            }
        },
        logoutUser() {
            localStorage.removeItem('kc_token');
            localStorage.removeItem('kc_refreshToken');
            localStorage.removeItem('kc_refreshToken_expire');
            localStorage.setItem("last_logout", new Date().getTime());
            this.keycloak.logout();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-navbar {
    background-color: var(--kds-color-acce1);
    height: 100%;
    margin: 0;
    color: var(--kds-color-atmo1);
    font-weight: bold;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'navbar-content'
        'support';
    text-align: center;
}

.navbar-content > img {
    width: 4rem;
    padding: 1rem;
}

.navbar-content > div {
    background-color: var(--kds-color-atmo1);
    color: var(--kds-color-acce1);
    font-size: 0.8rem;
    margin: 1rem 0;
    padding: 1rem;
}

.navbar-content > .user-info {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    color: var(--kds-color-atmo1);
    background-color: inherit;
}

.navbar-content > .user-info > a {
    text-decoration: none;
    color: var(--kds-color-atmo1);
}

.navbar-content > .user-info > a > span,
.navbar-content > .user-info > button {
    margin: 0 0.4rem;
    display: inline-block;
}

.navbar-content > .user-info > button {
    background: none;
    border: none;
    cursor: pointer;
    color: var(--kds-color-atmo1);
}

.support {
    display: grid;
    grid-template-columns: 0.4fr 1fr;
    grid-template-rows: min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'support-icon mail'
        'support-icon phone';

    color: var(--kds-color-atmo1);
    background: var(--kds-color-acce1);
    font-size: 0.7rem;
    grid-area: support;
    padding: 2rem 0;
}

.support-icon {
    grid-area: support-icon;
}

.mail {
    grid-area: mail;
    padding: 0 0.5rem;
}

.phone {
    grid-area: phone;
}

.navbar-content {
    grid-area: navbar-content;
}
</style>