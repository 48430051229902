<template>
    <div class="lsc-news kds-m-5">
        <div class="news-edit">
            <kds-button
                color="tertiary"
                @click="
                    () => {
                        popupEditOpen = !popupEditOpen;
                    }
                "
                class="kds-m-1"
                v-bind:class="{ hidden: !globalState.isAdmin }"
                >Edit</kds-button
            >

            <kds-button
                color="tertiary"
                @click="lastState()"
                class="kds-m-1"
                v-bind:class="{
                    hidden: !globalState.isAdmin || newsInfo.state === 'EDITING'
                }"
                >Last State</kds-button
            >

            <kds-button
                color="tertiary"
                @click="nextState()"
                class="kds-m-1"
                v-bind:class="{
                    hidden: !globalState.isAdmin || newsInfo.state !== 'EDITING'
                }"
                >Publish</kds-button
            >
            <kds-button
                color="tertiary"
                @click="nextState()"
                class="kds-m-1"
                v-bind:class="{
                    hidden:
                        !globalState.isAdmin || newsInfo.state !== 'PUBLISHED'
                }"
                >Archive</kds-button
            >

            <kds-button
                color="tertiary"
                @click="deleteNews()"
                class="kds-m-1"
                v-bind:class="{
                    hidden:
                        !globalState.isAdmin || newsInfo.state === 'PUBLISHED'
                }"
                >Delete</kds-button
            >
        </div>
        <div class="news-image">
            <img :src="pictureUploadsUrl + newsInfo.main_picture" />
        </div>
        <div class="news-title kds-h1">{{ newsInfo.title }}</div>
        <div class="news-content">
            <Markdown :source="displayedText" />

            <kds-button
                color="tertiary"
                @click="openReadmore()"
                class="kds-m-2"
                v-bind:class="{ hidden: !readmoreActive }"
            >
                Read more
            </kds-button>

            <kds-button
                color="tertiary"
                @click="closeReadmore()"
                class="kds-m-2"
                v-bind:class="{ hidden: readmoreActive }"
            >
                Read less
            </kds-button>
        </div>

        <LscPopup
            v-bind:popupOpen="popupEditOpen"
            v-bind:on-close="
                () => {
                    popupEditOpen = !popupEditOpen;
                }
            "
        >
            <template v-slot:header>Edit News</template>
            <template v-slot:content>
                <LscNewsAddNews
                    v-bind:on-close="
                        () => {
                            popupEditOpen = !popupEditOpen;
                        }
                    "
                    v-bind:newsObj="newsInfo"
                />
            </template>
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import { pictureUploadsUrl } from '@/config/config';
import newsHandler from '@/lib/newsHandler';
import LscNewsAddNews from '@/components/LscNews/LscNewsAddNews.vue';
import LscPopup from '@/components/LscStructure/LscPopup.vue';
import Markdown from 'vue3-markdown-it';

export default {
    components: {
        LscNewsAddNews,
        LscPopup,
        Markdown
    },
    data() {
        return {
            globalState: store.state,
            popupEditOpen: false,
            readmoreActive: true,
            displayedText: '',
            pictureUploadsUrl: ''
        };
    },
    name: 'LscNews',
    props: {
        newsInfo: Object
    },
    mounted: function () {
        this.pictureUploadsUrl = pictureUploadsUrl;
        if (this.newsInfo.id === this.$route.params.elementId) {
            this.openReadmore();
        }
    },
    updated: function () {
        this.toggleReadmore();
    },
    methods: {
        deleteNews() {
            if (confirm('do you really want to delete this news item')) {
                newsHandler.deleteNews(this.newsInfo.id).then(() => {
                    this.emitter.emit('lsc:event:newNewsAdded');
                });
            }
        },
        openReadmore() {
            this.readmoreActive = false;
            this.toggleReadmore();

            this.$router.replace({params: {elementId: this.newsInfo.id}});
        },
        closeReadmore() {
            this.readmoreActive = true;
            this.toggleReadmore();

            if (this.$route.params.elementId === this.newsInfo.id) {
                    this.$router.replace({params: {elementId: undefined}});
            }
        },
        toggleReadmore() {
            if (this.readmoreActive) {
                this.displayedText =
                    this.newsInfo.content.split('\n')[0] + ' ...';
            } else {
                let replacedOutText = this.newsInfo.content;
                try {
                    replacedOutText = this.newsInfo.content
                        .split('SERV_IMG_PATH')
                        .join(pictureUploadsUrl);
                } catch (e) {
                    console.log(e);
                }

                this.displayedText = replacedOutText;
            }
        },
        nextState() {
            let formObj = {};

            formObj.id = this.newsInfo.id;
            formObj.state =
                this.newsInfo.state === 'EDITING' ? 'PUBLISHED' : 'ARCHIVED';

            newsHandler
                .editNews(formObj)
                .then((res) => {
                    console.log(res.data);
                    this.emitter.emit('lsc:event:newNewsAdded');
                    this.onClose();
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        lastState() {
            let formObj = {};

            formObj.id = this.newsInfo.id;
            formObj.state =
                this.newsInfo.state === 'ARCHIVED' ? 'PUBLISHED' : 'EDITING';

            newsHandler
                .editNews(formObj)
                .then((res) => {
                    console.log(res.data);
                    this.emitter.emit('lsc:event:newNewsAdded');
                    this.onClose();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lsc-news {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 300px min-content min-content;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
        'news-edit'
        'news-image'
        'news-title'
        'news-content';
    text-align: left;

    box-shadow: 2px 2px 4px var(--kds-color-atmo5);
}

.news-edit {
    grid-area: news-edit;
}
.news-edit > kds-button.hidden {
    display: none;
}
.news-image {
    grid-area: news-image;
}

.news-image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.news-title {
    grid-area: news-title;
    padding: 0 1rem;
}

.news-content {
    grid-area: news-content;
    padding: 0 2rem 1rem 2rem;
}
</style>

