const { dfRq } = require('./endpointConnector');

function getChildNodes(childPath) {
    const formData = {};
    formData.node_path = childPath;

    return dfRq({
        method: "post",
        urlPath: `/nexus/`,
    }, {}, formData, true);
}

function getDetails(assetId) {
    return dfRq({
        method: "get",
        urlPath: `/nexus/details/${assetId}`,
    }, {}, {}, true);
}

module.exports = {
    getChildNodes,
    getDetails
};