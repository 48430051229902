<template>
    <div class="link-build">
        <LscPopup v-bind:popupOpen="popupOpen" v-bind:on-close="close">
            <template v-slot:header>Link Build</template>
            <template v-slot:content>
                <div class="linked-nodes">
                    <span v-for="node in linkedNodes" :key="node.Node.id">
                        <kds-tooltip class="tooltip-inline">
                            <kds-tag-actions
                                v-bind:node-id="node.Node.id"
                                class="kds-m-1"
                                height="m"
                                color="informative"
                                @close="disconnectFromNode"
                                icon-name="link"
                                :removable="linkedNodes.length > 1"
                            >
                                <b>{{node.Node.id === node_id ? '[CURRENT]' : ''}}</b>
                                {{ node.generatedLabel }} [{{ node.state }}]
                            </kds-tag-actions>
                            <div slot="tooltip">
                                <b>{{ node.Node.id === node_id ? '[CURRENT]' : '' }}</b>
                                {{ node.generatedLabel }} (state: {{ node.state }})
                            </div>
                        </kds-tooltip>
                    </span>
                </div>
                <kds-dropdown
                    v-if="
                        filteredEnterprisesToLink &&
                        filteredEnterprisesToLink.length > 0
                    "
                    class="kds-m-2"
                    label="Enterprise Name"
                    :value="
                        choosenEnterprise?.length > 0 ? [choosenEnterprise] : []
                    "
                    @input="
                        choosenEnterprise = $event.target.value[0];
                        choosenSite = '';
                        choosenLine = '';
                    "
                    :options="filteredEnterprisesToLink"
                ></kds-dropdown>
                <kds-dropdown
                    class="kds-m-2"
                    label="Site Name"
                    :value="choosenSite?.length > 0 ? [choosenSite] : []"
                    @input="
                        choosenSite = $event.target.value[0];
                        choosenLine = '';
                    "
                    :options="filteredSitesToLink"
                ></kds-dropdown>
                <kds-dropdown
                    placement="top"
                    class="kds-m-2"
                    label="Line Name"
                    :value="choosenLine?.length > 0 ? [choosenLine] : []"
                    @input="choosenLine = $event.target.value[0]"
                    :options="filteredLinesToLink"
                ></kds-dropdown>
                <p>
                    <kds-button
                        color="action"
                        @click="
                            choosenEnterprise = '';
                            choosenSite = '';
                            choosenLine = '';
                        "
                        class="kds-m-1"
                        ><kds-icon name="clear" size="xs"></kds-icon> Clear
                        Selection</kds-button
                    >

                    <kds-button
                        @click="connectToNode"
                        class="kds-m-1"
                        :disabled="isAlreadyChoosen"
                        >Link to Node</kds-button
                    >
                </p>
                <kds-message-alert
                    class="kds-m-2"
                    icon-size="xs"
                    hide-close="true"
                    v-if="isAlreadyChoosen"
                    type="warning"
                >
                    This node is already choosen - please choose another one
                </kds-message-alert>
            </template>
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import buildHandler from '@/lib/buildHandler';
import nodeHandler from '@/lib/nodeHandler';

import LscPopup from '@/components/LscStructure/LscPopup.vue';

export default {
    data() {
        return {
            globalState: store.state,
            linkedNodes: [],
            allNodes: [],
            choosenEnterprise: '',
            choosenSite: '',
            choosenLine: ''
        };
    },
    name: 'LscLinkBuild',
    components: {
        LscPopup
    },
    props: {
        popupOpen: Boolean,
        onClose: Function,
        buildInfo: Object
    },
    created: function () {},
    computed: {
        node_id() {
            return this.buildInfo.NodeBuilds[0].node_id;
        },
        build_id() {
            return this.buildInfo.NodeBuilds[0].build_id;
        },
        filteredEnterprisesToLink() {
            return this.allNodes
                .filter((f) => {
                    return f.type === 'ENTERPRISE';
                })
                .map((item) => {
                    return { value: item.id, label: item.name };
                });
        },

        // todo filter by id
        filteredSitesToLink() {
            return this.choosenEnterprise === ''
                ? []
                : this.filterAndPrepareForDropdown(
                      this.allNodes,
                      this.choosenEnterprise
                  );
        },
        filteredLinesToLink() {
            return this.choosenSite === ''
                ? []
                : this.filterAndPrepareForDropdown(
                      this.allNodes,
                      this.choosenSite
                  );
        },
        isAlreadyChoosen() {
            let linkToId = this.getLinkToId();
            let found = false;
            this.linkedNodes.forEach((node) => {
                if (node.Node.id === linkToId) {
                    found = true;
                }
            });
            return found;
        }
    },
    methods: {
        filterAndPrepareForDropdown(nodes, toMatchId) {
            return nodes
                .filter((f) => {
                    return f.parent_id === toMatchId;
                })
                .map((item) => {
                    return { value: item.id, label: item.name };
                });
        },
        getLinkToId() {
            let linkedTmp =
                this.choosenSite !== ''
                    ? this.choosenSite
                    : this.choosenEnterprise;

            return this.choosenLine !== '' ? this.choosenLine : linkedTmp;
        },
        loadNodes() {
            if (this.globalState.isAdmin) {
                nodeHandler
                    .allNodes()
                    .then((response) => {
                        this.allNodes = response.data;

                        let tmpNodes = {};

                        response.data.sort(this.compareNode).forEach((d) => {
                            if (d.parent_id === null) {
                                tmpNodes[d.id] = `[${d.type}] ${d.name}`;
                            } else {
                                tmpNodes[d.id] = `${tmpNodes[d.parent_id]} > [${
                                    d.type
                                }] ${d.name}`;
                            }
                        });

                        return buildHandler
                            .getLinkedNodes(this.build_id)
                            .then((response) => {
                                this.linkedNodes = response.data.map((n) => {
                                    n.generatedLabel = tmpNodes[n.Node.id];
                                    return n;
                                });
                            })
                            .catch(function (error) {
                                console.log(error);
                            });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        compareNode(a, b) {
            const nv = {
                ENTERPRISE: 2,
                SITE: 1,
                LINE: 0
            };

            let valA = nv[a.type];
            let valB = nv[b.type];

            if (valA < valB) {
                return 1;
            }
            if (valA > valB) {
                return -1;
            }
            return 0;
        },
        connectToNode() {
            let linkToId = this.getLinkToId();
            buildHandler
                .linkBuildAndNode(this.build_id, linkToId)
                .then(() => {
                    this.choosenEnterprise = '';
                    this.choosenSite = '';
                    this.choosenLine = '';
                    this.loadNodes();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        disconnectFromNode(e) {
            buildHandler
                .unlinkBuildAndNode(
                    this.build_id,
                    e.target.getAttribute('node-id')
                )
                .then(() => {
                    this.loadNodes();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        close() {
            this.emitter.emit('lsc:event:buildLinkingChanged', this.build_id);
            this.onClose();
        }
    },
    watch: {
        popupOpen: function (newVal, oldVal) {
            if (oldVal === false && newVal !== oldVal) {
                this.loadNodes();
            }
        }
    }
};
</script>

<style scoped>
    .tooltip-inline {
        display: inline-block;
    }

    kds-dropdown {
        --kds-dropdown-options-container-max-height: 150px; /* deprecated but necessary in current kds */
        --kds-dropdown-panel-max-height: 150px;
    }
</style>
