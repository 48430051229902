const { dfRq } = require('./endpointConnector');

function getLicensesForNode(nodeId) {
    return dfRq({
        method: "get",
        urlPath: `/licenses/from/${nodeId}`
    }, {}, {}, true);
}

function getLicensesById(licenseId) {
    return dfRq({
        method: "get",
        urlPath: `/licenses/${licenseId}`
    }, {}, {}, true);
}

function createLicense(currentNode, formData) {
    formData.node_id = currentNode;

    return dfRq({
        method: "put",
        urlPath: `/licenses/`
    }, {}, formData, true);
}

function deleteLicense(licenseId) {
    return dfRq({
        method: "delete",
        urlPath: `/licenses/${licenseId}`
    }, {}, {}, true);
}

function rearrangeLicenses(objectData) {

    const formData = {};
    formData.licenseList = JSON.stringify(objectData);

    return dfRq({
        method: "post",
        urlPath: `/licenses/rearrange`,
    }, {}, formData, true);
}


module.exports = {
    getLicensesForNode,
    getLicensesById,
    createLicense,
    deleteLicense,
    rearrangeLicenses
};