<template>
    <div class="lsc-document">
        <div class="doc-icon">
            <span class="material-icons-outlined md-18"> description </span>
        </div>
        <div class="doc-name kds-caption">
            {{ documentInfo.name }}
        </div>
        <div class="doc-type kds-overline">{{ documentInfo.type }}</div>
        <div class="doc-download">
            <button
                class="download"
                v-on:click="download()"
                v-bind:class="{ spin: downloadInProgress === true }"
            >
                <span class="material-icons-outlined md-18">
                    {{
                        downloadInProgress === '404'
                            ? 'sync_problem'
                            : downloadInProgress
                            ? 'sync'
                            : 'file_download'
                    }}
                </span>
            </button>
            <button
                class="edit"
                v-bind:class="{ hidden: !globalState.isAdmin }"
                v-on:click="editDoc()"
            >
                <span class="material-icons-outlined md-18"> edit </span>
            </button>
            <button
                class="download"
                v-bind:class="{ hidden: !globalState.isAdmin }"
                v-on:click="deleteDoc()"
            >
                <span class="material-icons-outlined md-18"> delete </span>
            </button>
        </div>

        <LscPopup
            v-bind:popupOpen="popups.editDocument"
            v-bind:on-close="
                () => {
                    popups.editDocument = !popups.editDocument;
                }
            "
        >
            <template v-slot:header>Edit Document</template>
            <template v-slot:content>
                <kds-dropdown
                    :value="[form.type]"
                    @input="form.type = $event.target.value[0]"
                    class="kds-mt-3"
                    label="Document Type"
                    placeholder="Make selection"
                    name="document_type"
                    :options="dropdownValues"
                ></kds-dropdown>

                <kds-input
                    label="Document Name"
                    name="name"
                    :value="form.name"
                    @input="form.name = $event.target.value"
                ></kds-input>
            </template>
            <template v-slot:footer>
                <kds-button
                    color="tertiary"
                    @click="editDocument"
                    v-bind:class="{
                        hidden: !globalState.isAdmin
                    }"
                    class="kds-m-2 add-document"
                    >Edit Document</kds-button
                ></template
            >
        </LscPopup>
    </div>
</template>

<script>
import { store } from '@/lib/globalStore';
import documentHandler from '@/lib/documentHandler';
import mime from 'mime-types';
import LscPopup from '@/components/LscStructure/LscPopup.vue';

export default {
    data() {
        return {
            globalState: store.state,
            downloadInProgress: false,
            node_build_state: null,
            node_id: null,
            build_id: null,
            dropdownValues: [
                { value: 'DOCU', label: 'DOCU' },
                { value: 'ISSUE', label: 'ISSUE' },
                { value: 'RELEASE_NOTE', label: 'RELEASE_NOTE' },
                { value: 'ZIP', label: 'ZIP' }
            ],
            form: {
                name: null,
                type: 'DOCU'
            },
            popups: {
                editDocument: false
            }
        };
    },
    name: 'LscDocument',
    components: { LscPopup },
    props: {
        documentInfo: Object
    },
    created: function () {
        this.form.name = this.documentInfo.name;
        this.form.type = this.documentInfo.type;
    },
    methods: {
        deleteDoc() {
            if (confirm('do you really want to delete this document')) {
                documentHandler
                    .deleteDocument(this.documentInfo.id)
                    .then(() => {
                        this.emitter.emit('lsc:event:newDocumentAdded');
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },
        editDoc() {
            this.popups.editDocument = true;
        },
        editDocument() {
            this.form.id = this.documentInfo.id;

            documentHandler
                .editDocument(this.form)
                .then(() => {
                    this.emitter.emit('lsc:event:newDocumentAdded');
                });

            this.popups.editDocument = false;
        },
        download() {
            this.downloadInProgress = true;

            documentHandler
                .downloadDocument(this.documentInfo.id)
                .then((response) => {
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(
                        new Blob([response.data])
                    );

                    const extension =
                        this.documentInfo.file_path.split('.')[
                            this.documentInfo.file_path.split('.').length - 1
                        ];

                    // DEFINE file ending
                    const fileExtension = extension
                        ? extension
                        : mime.extension(this.documentInfo.mimetype);

                    link.download = `${this.documentInfo.name}.${fileExtension}`;
                    link.click();
                    this.downloadInProgress = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.downloadInProgress = '404';
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(-360deg);
    }
}

.spin {
    animation-name: spin;
    animation-duration: 4000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

button.download {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 1rem;
}

button.download.hidden {
    display: none;
}

button.edit {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0 1rem;
}

button.edit.hidden {
    display: none;
}

.lsc-document {
    display: grid;
    grid-template-columns: min-content 4fr 1fr min-content;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'doc-icon doc-name doc-type doc-download';

    padding: 0.5rem;
    margin: 0.5rem 4rem 0.5rem 0;
    /*border-left: 4px solid #0060ff;*/
}

.lsc-document > div {
    display: flex;
    align-items: center;
}

.doc-icon {
    grid-area: doc-icon;
}

.doc-name {
    grid-area: doc-name;
}

.doc-type {
    grid-area: doc-type;
}

.doc-download {
    grid-area: doc-download;
}
</style>

