const { dfRq } = require('./endpointConnector');


function getRollsByNode(nodeId) {

    return dfRq({
        method: "get",
        urlPath: `/rolls/${nodeId}`
    }, {}, {}, true);
}

function createRoll(formData) {

    return dfRq({
        method: "put",
        urlPath: `/rolls/`
    }, {}, formData, true);
}

function deleteRoll(nodeId, currentRoll) {

    return dfRq({
        method: "delete",
        urlPath: `/rolls/${nodeId}/${currentRoll}`
    }, {}, {}, true);
}

module.exports = {
    getRollsByNode,
    createRoll,
    deleteRoll
};