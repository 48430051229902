<template>
    <div
        class="lsc-home"
        v-bind:class="{
            'nav-open': navStateOpen,
            'nav-closed': !navStateOpen
        }"
    >
        <div class="mobile-menue">
            <span
                v-if="!navStateOpen"
                class="material-icons-outlined"
                @click="toggleMenu"
            >
                menu
            </span>
            <span
                v-if="navStateOpen"
                class="material-icons-outlined"
                @click="toggleMenu"
            >
                close
            </span>
        </div>
        <div class="nav-bar">
            <LscNavBar />
        </div>
        <div class="node-nav">
            <draggable
                v-model="nodes"
                v-bind="dragOptions"
                group="enterprise"
                @start="drag = true"
                @end="drag = false"
                item-key="id"
                :disabled="!globalState.isAdmin || globalState.dragDisabled"
                @change="nodesRearranged"
            >
                <template #item="{ element }">
                    <div>
                        <LscOrgNode
                            v-bind:nodeId="element.id"
                            v-bind:name="element.name"
                            v-bind:type="element.type"
                            v-bind:isInitialActive="
                                $route.params.nodeId
                                    ? $route.params.nodeId
                                    : nodes[0].id
                            "
                            v-bind:spacer="0"
                            v-bind:parent-perform-load="performLoad"
                        />
                    </div>
                </template>
            </draggable>

            <LscLoadingSpinner v-if="loadingSpinner.nodes" class="kds-m-5" />

            <div class="centered">
                <kds-button
                    color="tertiary"
                    @click="openAddEnterprisePopup"
                    v-bind:class="{ hidden: !globalState.isAdmin }"
                    class="kds-m-2 add-enterprise"
                    >Add Enterprise</kds-button
                >
            </div>
        </div>
        <div class="node-detail"><LscActiveNodeView /></div>

        <LscPopup
            v-bind:popupOpen="popups.addEnterpriseOpen"
            v-bind:on-close="
                () => {
                    popups.addEnterpriseOpen = !popups.addEnterpriseOpen;
                }
            "
        >
            <template v-slot:header>Add Enterprise</template>
            <template v-slot:content>
                <kds-input
                    class="kds-m-2"
                    label="Enterprise Name"
                    name="name"
                    :value="form.name"
                    @input="form.name = $event.target.value"
                ></kds-input>

                <kds-dropdown
                    class="kds-m-2"
                    label="Roll Name"
                    name="roll_name"
                    :value="[form.roll_name]"
                    @input="form.roll_name = $event.target.value[0]"
                    :options="availableRolls"
                ></kds-dropdown>

                <p class="kds-caption">
                    if the required roll is not visible - check if your user has
                    the role and try to log out and log in again!
                </p>

                <kds-button @click="submitAddEnterprise" class="kds-m-2"
                    >Add</kds-button
                >
            </template>
        </LscPopup>
    </div>
</template>

<script>
// @ is an alias to /src
import { store } from '@/lib/globalStore';
import LscActiveNodeView from '@/components/LscStructure/LscActiveNodeView.vue';
import nodeHandler from '@/lib/nodeHandler';
import rollHandler from '@/lib/rollHandler';
import LscNavBar from '@/components/LscStructure/LscNavBar.vue';
import LscOrgNode from '@/components/LscStructure/LscOrgNode.vue';
import LscPopup from '@/components/LscStructure/LscPopup.vue';
import LscLoadingSpinner from '@/components/LscStructure/LscLoadingSpinner.vue';

import draggable from 'vuedraggable';

export default {
    data() {
        return {
            globalState: store.state,
            navStateOpen: false,
            nodes: null,
            popups: {
                addEnterpriseOpen: false
            },
            form: {
                name: '',
                roll_name: 'lsc-user',
                node_id: null,
                is_admin: false
            },
            loadingSpinner: {
                nodes: false
            },
            availableRolls: [
                { value: 'lsc-user', label: 'lsc-user' },
                { value: 'lsc-admin', label: 'lsc-admin' }
            ]
        };
    },
    name: 'LscHome',
    components: {
        LscNavBar,
        LscOrgNode,
        LscPopup,
        LscActiveNodeView,
        LscLoadingSpinner,
        draggable
    },
    created: function () {
        // todo find better option for this
        if (!this.keycloak.authenticated) {
            let redirectPath = this.$router.currentRoute.value.path;
            let lastLogoutTimeDiff =
                new Date().getTime() -
                parseInt(localStorage.getItem('last_logout'));

            if (lastLogoutTimeDiff < 2000) {
                // last logout happened less than 2s ago - do not redirect to current page
                this.$router.push({ name: 'Login' });
            } else {
                this.$router.push({
                    path: '/app/login-pending',
                    query: { redirect: redirectPath }
                });
            }
        }

        let tmpDropdownRoles = [];
        try {
            const kcRoles =
                this.keycloak.tokenParsed.resource_access[this.globalState.resourceClient].roles;

            kcRoles.map((role) => {
                tmpDropdownRoles.push({
                    value: role,
                    label: role
                });
            });
        } catch (e) {
            console.log(e);
        }

        if (tmpDropdownRoles !== []) {
            this.availableRolls = tmpDropdownRoles;
        }

        this.performLoad();
    },
    methods: {
        toggleMenu() {
            this.navStateOpen = !this.navStateOpen;
        },
        openAddEnterprisePopup() {
            this.popups.addEnterpriseOpen = !this.popups.addEnterpriseOpen;
        },
        nodesRearranged() {
            nodeHandler
                .rearrangeNodes(this.nodes)
                .then((response) => {
                    console.log(response);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        submitAddEnterprise() {
            this.popups.addEnterpriseOpen = false;

            // create enterprise
            nodeHandler
                .createNode(null, '', this.form)
                .then((response) => {
                    this.form.node_id = response.data.id;

                    // add roll
                    rollHandler.createRoll(this.form).then(() => {
                        this.performLoad();
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        performLoad() {
            this.loadingSpinner.nodes = true;
            nodeHandler
                .allEnterprises()
                .then((response) => {
                    this.nodes = response.data;
                    this.loadingSpinner.nodes = false;
                })
                .catch(function (error) {
                    if (
                        this.loadingSpinner !== undefined &&
                        this.loadingSpinner !== null
                    ) {
                        this.loadingSpinner.nodes = false;
                    }
                    console.log(error);
                });
        }
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                disabled: false,
                ghostClass: 'ghost'
            };
        }
    }
};
</script>

<style scoped>
/* DRAG STYLES */

.button {
    margin-top: 35px;
}
.flip-list-move {
    transition: transform 0.5s;
}
.no-move {
    transition: transform 0s;
}
.ghost {
    opacity: 0.5;
    background: var(--kds-color-sema7);
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
.list-group-item i {
    cursor: pointer;
}

/* DRAG STYLES */

.lsc-home {
    display: grid;
    grid-template-columns: 1fr 1.5fr 7fr;
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas: 'nav-bar node-nav node-detail ';
    height: 100%;
}

.nav-bar {
    grid-area: nav-bar;
    padding: 0;
}

.node-nav {
    grid-area: node-nav;
    background-color: var(--kds-color-atmo3);
    overflow-y: scroll;
}

.node-detail {
    grid-area: node-detail;
}

.mobile-menue {
    display: none;
}

.add-enterprise.hidden {
    display: none;
}

@media only screen and (max-device-width: 800px) {
    .lsc-home.nav-open {
        grid-template-columns: 1fr 1.5fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            'mobile-menue mobile-menue'
            'nav-bar node-nav';
    }

    .lsc-home.nav-open .node-detail {
        display: none;
    }

    .lsc-home.nav-closed {
        grid-template-columns: 1fr;
        grid-template-rows: min-content 1fr;
        grid-template-areas:
            'mobile-menue'
            'node-detail';
    }

    .lsc-home.nav-closed .nav-bar,
    .lsc-home.nav-closed .node-nav {
        display: none;
    }

    .mobile-menue {
        grid-area: mobile-menue;
        background: var(--kds-color-acce1);
        color: var(--kds-color-atmo1);
        display: flex;
        justify-content: space-around;
        padding: 0.5rem;
    }
}
</style>

<style>
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

#app,
#app > div {
    height: 100%;
}
</style>
